import { Tab } from '@headlessui/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueries } from 'react-query';
import { getReservationListBooked, getReservationListCanceled } from '../../api/reservation';
import { classNames } from '../../util';
import { SkeletonCarInfoDesktop } from '../Listing/desktop/Skeleton.CarInfo.Desktop';
import ReservationList from './components/ReservationList';
import { useLocation } from 'react-router-dom';
import { loadPhoneEmailFromStorage } from '../ReservationCheck/ReservationCheck.form.viewmodel';
import { WrapperCancelPopup } from '^/components/Popup/ReservationCancelPopup';
import { RESERVATION_CHECK } from '^/constants/routes';
import { SubEmptyReservation } from './SubEmptyReservation';
import { css } from '@emotion/react';
import { borders } from '^/constants/commonStyles';

export const ReservationsListPage = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const index = location && location?.state && location.state?.index;
  const [idRes, setIdRes] = useState('initial');
  const [selectedIndex, setSelectedIndex] = useState(index || 0);
  const [open, setOpen] = useState(false);
  const [popOpen, setPopOpen] = useState(false);
  const [cancelInfo, setCancelInfo] = useState({});

  useEffect(() => {
    if (!loadPhoneEmailFromStorage()) {
      window.location.pathname = RESERVATION_CHECK;
    }
  }, []);

  const [
    { data: bookedRes, isLoading: isBookedLoading, isError: isBookedError },
    { data: cancelRes, isLoading: isCancelLoading, isError: isCancelError },
  ] = useQueries([
    {
      queryKey: ['bookReservation', idRes],
      queryFn: () => {
        return getReservationListBooked(
          loadPhoneEmailFromStorage()['email'],
          loadPhoneEmailFromStorage()['phone'],
        );
      },
      enabled: !!idRes,
    },
    {
      queryKey: ['cancelReservation', idRes],
      queryFn: () => {
        return getReservationListCanceled(
          loadPhoneEmailFromStorage()['email'],
          loadPhoneEmailFromStorage()['phone'],
        );
      },
      enabled: !!idRes,
    },
  ]);
  if (isBookedError || isCancelError) {
    return <span>{error.message}</span>;
  }

  return (
    <>
      <div>
        <WrapperCancelPopup
          title="정말 예약을 취소하겠습니까?"
          popOpen={popOpen}
          setPopOpen={setPopOpen}
          cancelInfo={cancelInfo}
          setOpen={setOpen}
          isMobile={false}
        />
      </div>
      <div className="tablet-max:pt-[60px] tablet:pb-6  m-[20px]">
        <div className="tablet-max:max-w-lg tablet-max:pb-8 tablet-max:rounded-lg">
          <div
            className="fixed top-[72px] bg-white z-20 pt-[70px] pb-[40px]"
            style={{
              width: '1040px',
            }}
          >
            <h4 className="font-bold text-primary-gray text-enomous tablet-max:pt-[30px] tablet-max:pb-5 tablet-max:-mx-6 tablet-max:px-6 tablet-max:z-20 tablet-max:text-semihuge tablet-max:w-full tablet-max:bg-white tablet-max:fixed tablet-max:top-[60px]">
              예약조회
            </h4>
          </div>
          <section className="relative tablet-max:flex-col tablet-max:mt-5 tablet-max:-mx-6">
            <Tab.Group
              as="div"
              className=" h-full tablet:flex w-full"
              selectedIndex={selectedIndex}
              onChange={setSelectedIndex}
            >
              <div
                className="fixed tablet:top-[198px] tablet-max:top-[136px] z-20 bg-white"
                style={{
                  minWidth: '1040px',
                }}
              >
                <Tab.List className="flex rounded h-fit w-fit gap-[36px] tablet-max:px-6">
                  <Tab
                    key="completed"
                    className={({ selected }) =>
                      classNames(
                        'tablet:text-semilarge text-big text-left min-w-fit text-primary-gray focus:outline-none border-b',
                        selected
                          ? 'font-bold border-primary-skyblue'
                          : 'border-transparent font-light',
                      )
                    }
                    style={{
                      backgroundColor: 'white',
                    }}
                    onClick={() => {
                      window.scrollTo(0, 0);
                    }}
                  >
                    완료된 예약
                  </Tab>
                  <Tab
                    key="canceled"
                    className={({ selected }) =>
                      classNames(
                        'tablet:text-semilarge  text-big text-left min-w-fit text-primary-gray focus:outline-none p-0 py-3 border-b',
                        selected
                          ? 'font-bold border-primary-skyblue '
                          : 'border-transparent font-light',
                      )
                    }
                    onClick={() => {
                      window.scrollTo(0, 0);
                    }}
                  >
                    취소된 예약
                  </Tab>
                </Tab.List>
                <hr className="tablet-max:visible tablet:invisible secondary-gray-light" />
              </div>
              <Tab.Panels className="tablet:flex-1 tablet:mt-[218px] tablet-max:mt-[79px] tablet-max:px-6">
                <Tab.Panel key="listCompleted">
                  {isBookedLoading ? (
                    <SkeletonCarInfoDesktop />
                  ) : bookedRes.data.length ? (
                    <ReservationList list={bookedRes.data} />
                  ) : (
                    <SubEmptyReservation />
                  )}
                </Tab.Panel>
                <Tab.Panel key="listCancel">
                  {isCancelLoading ? (
                    <SkeletonCarInfoDesktop />
                  ) : cancelRes.data.length ? (
                    <ReservationList list={cancelRes.data} />
                  ) : (
                    <SubEmptyReservation />
                  )}
                </Tab.Panel>
              </Tab.Panels>
            </Tab.Group>
          </section>
        </div>
      </div>
    </>
  );
};

export default ReservationsListPage;
