import { makeAutoObservable } from 'mobx';
import { NavigateFunction } from 'react-router';

import { AppContext } from '^/AppContext/AppContext';
import { PaymentTypes, VendorCompanyIDType } from '^/types/__BrandedTypes';
import { AGENTS } from '^/types/AgentTypes';
import { ExtractIterableQuery } from '^/types/utils/ExtractIterableQuery';
import { filterNotDefineds } from '^/types/utils/isDefined';
import { createKey } from '^/util';
import { getItemByChannel } from '^/util/byChannel';
import { getSubdomain } from '^/util/index';

import { checkEquips } from '../../../api/Search';
import { ListingPage2RouteParams } from '../ListingPage2RouteParams';

import type { FilteredCarListItemType } from './ListingPage2.LoadedViewModel';

const brandPriority: Record<VendorCompanyIDType, number> = {
  ZE: 0, // hertz
  ZR: 1, // dollar
  OT: 2, // online-travel
  TM: 3, // tumon
  RC: 4, // rich
} as const;

export class ListingPage2CardViewModel {
  constructor(
    public appContext: AppContext,
    public data: FilteredCarListItemType,
    public routeParams: ListingPage2RouteParams,
  ) {
    makeAutoObservable(this);
  }

  get lowestPricePayment() {
    const podPayments = filterNotDefineds(this.data.carChoices.map((a) => a.POD));
    const podMinPayment = podPayments.min(
      (a, b) =>
        Math.ceil(a.payment.domesticPrice - a.payment.discountDomestic) -
        Math.ceil(b.payment.domesticPrice - b.payment.discountDomestic),
    );

    const ppdPayments = filterNotDefineds(this.data.carChoices.map((a) => a.PPD));
    const ppdMinPayment = ppdPayments.min(
      (a, b) =>
        Math.ceil(a.payment.domesticPrice - a.payment.discountDomestic) -
        Math.ceil(b.payment.domesticPrice - b.payment.discountDomestic),
    );
    if (!podMinPayment) {
      return ppdMinPayment;
    }
    if (!ppdMinPayment) {
      return podMinPayment;
    }
    if (
      Number(podMinPayment.payment.foreignCurrency) < Number(ppdMinPayment.payment.foreignCurrency)
    ) {
      return podMinPayment;
    }
    if (
      Number(ppdMinPayment.payment.foreignCurrency) < Number(podMinPayment.payment.foreignCurrency)
    ) {
      return ppdMinPayment;
    }
    return ppdMinPayment;
  }

  get lowestDomesticPrice() {
    return (
      Number(this.lowestPricePayment?.payment.domesticPrice) -
      Number(this.lowestPricePayment?.payment.discountDomestic)
    );
  }

  get groupedByVendors() {
    const byVendors = this.data.carChoices.groupBy((k) => k.core.company.id);

    const byVendorsWithMinPrices = byVendors
      .map(([brandId, carChoices]) => {
        // 1. find minimum POD price
        const carChoicesWithCore = carChoices.map((c) => {
          return {
            ...c,
            POD: c.POD
              ? {
                  ...c.POD,
                  core: c.core,
                }
              : undefined,
            PPD: c.PPD
              ? {
                  ...c.PPD,
                  core: c.core,
                }
              : undefined,
          };
        });
        const minPPDChoice = filterNotDefineds(carChoicesWithCore.map((c) => c.PPD)).min(
          (ppd) => ppd.payment.domesticPrice,
        );

        const minPODChoice = filterNotDefineds(carChoicesWithCore.map((c) => c.POD)).min(
          (pod) => pod.payment.domesticPrice,
        );

        return [
          brandId,
          {
            carChoices,
            minPPDChoice,
            minPODChoice,
          },
        ] as const;
      })
      .sort((a, b) => {
        return brandPriority[a[0]] - brandPriority[b[0]];
      });
    return byVendorsWithMinPrices;
  }

  gotoBooking(
    paymentType: PaymentTypes,
    // FIXME: remove after moving booking page to type-router
    navigate: NavigateFunction,
    item:
      | never
      | NonNullable<ExtractIterableQuery<typeof this['groupedByVendors']>[1]['minPODChoice']>
      | NonNullable<ExtractIterableQuery<typeof this['groupedByVendors']>[1]['minPPDChoice']>,
  ) {
    const routeParams = {
      ...this.routeParams,
      rLocationCode: this.routeParams.rLocationCode || this.routeParams.pLocationCode,
    };
    const key = createKey([
      getItemByChannel(getSubdomain(), AGENTS),
      item.core.company.id,
      item.core.destination,
      routeParams.pLocationCode,
      routeParams.rLocationCode,
      routeParams.pDatetime,
      routeParams.rDatetime,
      item.core.modelCode,
      item.core.codeContext,
    ]);
    checkEquips({
      key,
      vendor: item.core.company.id,
      pickup_location: routeParams.pLocationCode,
      return_location: routeParams.rLocationCode,
      pickup_datetime: routeParams.pDatetime,
      return_datetime: routeParams.rDatetime,
      birth_day: routeParams.birthday,
      car_code: item.core.modelCode,
      code_context_car_code: item.core.codeContext,
      price_code: item.payment.price_code,
    });
    window.scrollTo(0, 0);

    const routeState = {
      carInfo: {
        key,
        vendor: item.core.company.id,
        rentingInfo: item.core.rentingInfo,
        destination: item.core.destination,
        modelCode: item.core.modelCode,
        codeContext: item.core.codeContext,
        paymentType,
        coupon: item.payment.coupon,
        currency: item.payment.foreignCurrencyCode,
        package: item.payment.price_code,
      },
      listingInfo: {
        ...routeParams,
        retry: 0,
      },
      loading: true,
    };

    sessionStorage.setItem('LOCATION_STATE', JSON.stringify(routeState));
    navigate(`/booking?id=${item.core.id}`, {
      state: {
        carInfo: {
          key,
          vendor: item.core.company.id,
          rentingInfo: item.core.rentingInfo,
          destination: item.core.destination,
          modelCode: item.core.modelCode,
          codeContext: item.core.codeContext,
          paymentType,
          coupon: item.payment.coupon,
          currency: item.payment.foreignCurrencyCode,
          package: item.payment.price_code,
        },
        listingInfo: {
          ...routeParams,
          retry: 0,
        },
        loading: true,
      },
    });
  }
}
