import { useEffect, useState } from 'react';

const LoadingWithVendorLogo: React.FC = () => {
  const logos = [
    '/vendors/logo_hertz@2x.png',
    '/vendors/logo_dollar@2x.png',
    '/vendors/logo_web-rentacar@3x.png',
  ];
  const texts = [
    '전 세계 최저가 렌터카를 찾고 있어요.',
    '트리모에서 간편하게 렌터카 가격을 비교해 보세요.',
    '허츠 골드멤버십에 가입하시면 다양한 혜택을 받으실 수 있어요.',
  ];

  const [logo, setLogo] = useState<string>('');
  const [text, setText] = useState<string>('');

  useEffect(() => {
    // 초기 로고와 문구 설정
    setLogo(getRandomElement(logos));
    setText(getRandomElement(texts));
  }, []);

  useEffect(() => {
    const logoTimer = setInterval(() => {
      setLogo((prevLogo) => getRandomElement(logos.filter((item) => item !== prevLogo)));
    }, 2000);

    const textTimer = setInterval(() => {
      setText((prevText) => getRandomElement(texts.filter((item) => item !== prevText)));
    }, 4000);

    return () => {
      clearInterval(logoTimer);
      clearInterval(textTimer);
    };
  }, []);

  const getRandomElement = (array: string[]): string => {
    const randomIndex = Math.floor(Math.random() * array.length);
    return array[randomIndex];
  };

  return (
    <div className="relative">
      <div className="fixed inset-0 z-40 flex h-full w-full flex-col items-center justify-center gap-6 bg-black opacity-30"></div>
      <div className="fixed inset-0 z-40 flex h-full w-full items-center justify-center">
        <div className="justify- center flex w-full  flex-col items-center">
          <div className="flex h-[120px] w-[120px] items-center justify-center rounded-[30px] bg-white px-4">
            <img src={logo} className="w-full" />
          </div>
          <p className="mt-[30px] h-[70px] w-full break-keep px-6 text-center font-semibold tracking-[-0.03px] text-white mobile:text-[22px] desktop:text-[25px]">
            {text}
          </p>
        </div>
      </div>
    </div>
  );
};

export default LoadingWithVendorLogo;
