/* eslint-disable react/jsx-no-bind */
import { useEffect } from 'react';

import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useMediaQuery } from 'react-responsive';

import { Layout } from '^/components/Layout';
import LoadingWithVendorLogo from '^/components/Loading/LoadingWithVendorLogo';
import { typeRouter } from '^/typeRoute/typeRoutes';
import { increaseFreshness } from '^/types/__ResultType';

import { loadPhoneEmailFromStorage } from '../ReservationCheck/ReservationCheck.form.viewmodel';

import ConfirmReservationCancelPopup from './ConfirmReservationCancelPopup';
import { ReservationDetailPageDesktop } from './desktop/ReservationDetailsPage.Desktop';
import { ReservationDetailPageMobile } from './mobile/ReservationDetailsPage.Mobile';
import { ReservationDetailsPageViewModel } from './ReservationDetailsPage.viewmodel';
import { useSetRecoilState } from 'recoil';
import { errorButtonTextState, errorMsgState, errorState, handleCloseState } from '^/recoil/atoms';
import { CancellationReasonType } from '^/types/CancellationReasonType';
import { ServerErrorView } from '^/commonComponents/ui/ErrorViews/ServerErrorView';

export const ReservationDetailsPage: React.FC<{
  viewmodel: ReservationDetailsPageViewModel;
}> = observer(function ReservationDetailsPage({ viewmodel }) {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const setIsError = useSetRecoilState(errorState);
  const setErrorMsg = useSetRecoilState(errorMsgState);
  const setHandleErrorClose = useSetRecoilState(handleCloseState);
  const setErrorButtonText = useSetRecoilState(errorButtonTextState);

  useEffect(() => {
    if (!loadPhoneEmailFromStorage()) {
      window.location.pathname = '/reservation_check';
    }
  }, []);

  function onClickCancelReservation() {
    viewmodel.showCancelConfirmPopup.set(true);
  }

  async function onClickCancelReservationConfirm(cancelReason: CancellationReasonType) {
    const cancelResult = await viewmodel.cancelReservation(cancelReason);
    viewmodel.showCancelConfirmPopup.set(false);
    setErrorButtonText('확인');
    if (cancelResult.status === 'success') {
      setErrorMsg('예약이 취소되었습니다.');
      setHandleErrorClose(() => () => typeRouter.session.replace('/reservation_check'));
    }
    if (cancelResult.status === 'failure') {
      setErrorMsg(
        cancelResult.failure.error.message || '예약 취소에 실패했습니다. 고객센터로 문의해주세요.',
      );
    }
    setIsError(true);
    runInAction(() => {
      viewmodel.freshness = increaseFreshness(viewmodel.freshness);
    });
  }

  if (
    viewmodel.showLoading.value === true ||
    (viewmodel.reservationDetail.status === 'loading' &&
      viewmodel.reservationDetail.value === undefined)
  ) {
    return <LoadingWithVendorLogo />;
  }

  if (viewmodel.reservationDetail.status === 'failure') {
    return (
      <ServerErrorView
        onClickBack={() => {
          typeRouter.session.back();
        }}
        onClickRetry={() => {
          window.location.reload();
        }}
      />
    );
  }

  if (isMobile) {
    return (
      <Layout withoutFooter>
        <ConfirmReservationCancelPopup
          visible={viewmodel.showCancelConfirmPopup}
          handleCancel={() => viewmodel.showCancelConfirmPopup.set(false)}
          handleConfirm={onClickCancelReservationConfirm}
          isMobile={isMobile}
        />
        <ReservationDetailPageMobile
          viewmodel={viewmodel}
          onClickCancelReservation={onClickCancelReservation}
        />
      </Layout>
    );
  }
  return (
    <Layout withoutMaxWidth>
      <ReservationDetailPageDesktop
        viewmodel={viewmodel}
        onClickCancelReservation={onClickCancelReservation}
      />
      <ConfirmReservationCancelPopup
        visible={viewmodel.showCancelConfirmPopup}
        handleCancel={() => viewmodel.showCancelConfirmPopup.set(false)}
        handleConfirm={onClickCancelReservationConfirm}
        isMobile={isMobile}
      />
    </Layout>
  );
});
