/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';

import { css } from '@emotion/css';
import { format } from 'date-fns';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { borders, COLORS2_AS_FOREGROUND, font2 } from '^/constants/commonStyles';
import { isReservationCancelDisabled } from '^/pages/ReservationList/isReservationDisabled';
import { ReservationDetailed } from '^/types/ReservationDetailed/RawReservationDetailed';
import { formatKRWWonAmount } from '^/util/formatKRWWonAmount';

import { Horizon } from '../../../../ReservationList/components/common/Horizon';

import { ReservationDetailsMobileBillPODArea } from './ReservationDetailsMobile.Bill.PODArea';
import { ReservationDetailsMobileBillPPDArea } from './ReservationDetailsMobile.Bill.PPDArea';
import { CancellationWarningBeforePickup72Hours } from '^/pages/ReservationDetail/CancellationWarningBeforePickup72hours';
import { formatDateString } from '^/util/dateFormat';
// import { actions, sendTrackingLog } from '^/api/trackingLog';

const wonCurrency = 'KRW';

export const ReservationDetailsBill: React.FC<{
  data: ReservationDetailed;
  onClickCancelReservation(reservationToCancel: ReservationDetailed): void;
}> = observer(function ReservationDetailsBill({ data, onClickCancelReservation }) {
  const { t } = useTranslation();
  const pickupDate = new Date(data.pickup_datetime);
  // const tax = data.tax && JSON.parse(data.tax);
  const isCancelDisabled = isReservationCancelDisabled({
    pickupDate,
    cancelEnabled: data.cancelEnabled,
    payType: data.pay_type,
  });
  return (
    <div
      className={[
        COLORS2_AS_FOREGROUND.main.MrtBlack,
        // borders.DEBUG_blueBorderDashed,
      ].join(' ')}
    >
      <pre
        style={{
          color: 'blue',
          backgroundColor: 'yellow',
          display: 'none',
        }}
      >
        {JSON.stringify(data, null, 2)}
      </pre>
      <h3 className={font2.Headline1_Bold}>
        {data.res_status === 'booked' ? '요금 내역' : '취소 내역'}
      </h3>

      <Horizon marginTop="30px" />

      {data.pay_type === 'POD' ? (
        <ReservationDetailsMobileBillPODArea data={data} />
      ) : (
        <ReservationDetailsMobileBillPPDArea data={data} />
      )}
      {data.res_status === 'canceled' && (
        <>
          <div className="mt-4 flex flex-row  justify-between">
            <p className="text-semilarge mobile:text-big">{t('cancelDate')}</p>
            <p className="text-semilarge font-bold">
              {formatDateString(data.date_cancel, 'YYYY.MM.DD HH:mm (z)', 'Asia/Seoul') || '-'}
            </p>
          </div>
        </>
      )}
      {data.res_status === 'canceled' && data.pay_type === 'PPD' && (
        <>
          <Horizon marginTop="16px" marginBottom="16px" />
          <div className="mt-4 flex flex-row justify-between tablet:mt-0">
            <p className="text-big tablet:text-semilarge">환불 금액</p>
            <p className="text-semilarge font-bold">{formatKRWWonAmount(data.rate_amount)} 원</p>
          </div>
          <div className="mt-4 flex flex-row  justify-between">
            <p className="text-semilarge mobile:text-big">취소 수수료</p>
            <p className="text-semilarge font-bold">0 원</p>
          </div>
        </>
      )}
      {data.res_status === 'booked' && (
        <div>
          <button
            type="button"
            onClick={(e) => {
              e.preventDefault(); // 스크롤 상단 이동 방지
              onClickCancelReservation(data);
              // sendTrackingLog({
              //   action: actions.RESERVATION_DETAIL.CLICK_RESERVATION_CANCEL,
              // });
            }}
            disabled={isCancelDisabled}
            className="
              mt-4 w-full rounded-trimo-5
              bg-trimo-bg p-3
            text-center font-bold text-primary-gray
              hover:cursor-pointer hover:bg-primary-skyblue hover:text-primary-white
            focus:text-white
              active:text-white
            disabled:cursor-not-allowed disabled:bg-secondary-gray disabled:text-neutral-300"
          >
            {t('cancelBooking')}
          </button>
          {isCancelDisabled && (
            <div
              className="
                mt-[12px]
                flex justify-center
                text-small
                text-primary-gray
              "
            >
              <CancellationWarningBeforePickup72Hours />
            </div>
          )}
        </div>
      )}
    </div>
  );
});
