import React from 'react';

import { css, cx } from '@emotion/css';
import { observer } from 'mobx-react-lite';

import { SPACING, COLORS2 } from '^/constants/commonStyles';

import { ListingPage2CardViewModel } from '../viewmodel/ListingPage2.card.viewmodel';

import { ListingPage2DesktopCardBodyArea } from './ListingPage2.Desktop.Card.Contents';
import { ListingPage2DesktopCardSummary } from './ListingPage2.Desktop.Card.Summary';

export const ListingPage2DesktopCard: React.FC<{
  viewmodel: ListingPage2CardViewModel;
}> = observer(function ListingPage2DesktopCard({ viewmodel }) {
  return (
    <div
      className={cx(
        css`
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          width: 100%;

          /* padding: ${SPACING.spacing24}; */
          background-color: ${COLORS2.main.MrtWhite};
          border-radius: 10px;

          /* z-index: 1000000000; */
        `,
        // borders.DEBUG_greenBorderDashed,
      )}
    >
      <ListingPage2DesktopCardSummary viewmodel={viewmodel} />
      <ListingPage2DesktopCardBodyArea viewmodel={viewmodel} />
    </div>
  );
});
