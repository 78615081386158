/* eslint-disable max-len */
import { observer } from 'mobx-react-lite';

import { AppContext } from '^/AppContext/AppContext';
import { Layout } from '^/components/Layout/index';
import { HomeWrapper } from '^/pages/Home/HomeWrapper';
import { ReservationCheckPageWrapper } from '^/pages/ReservationCheck/ReservationCheck.page.wrapper';
import ReservationDetailOnlineTravel from '^/pages/ReservationDetail/ReservationDetailOnlineTravel';
import { ReservationDetailsPage } from '^/pages/ReservationDetail/ReservationDetailsPage';
import { ReservationDetailsPageViewModel } from '^/pages/ReservationDetail/ReservationDetailsPage.viewmodel';
import ReservationsListPage from '^/pages/ReservationList';
import { getCurrentFreshness } from '^/types/__ResultType';

import { typeRouter } from './typeRoutes';
import { ListingPage2Wrapper } from '^/pages/Listing/ListingPage2Wrapper';

export const TypeRouteRenderer: React.FC<{
  appContext: AppContext;
}> = observer(function TypeRouteRenderer({ appContext }) {
  const route = typeRouter.useRoute();
  if (route.name === 'home2') {
    if (!route.params.freshness) {
      setTimeout(() => {
        typeRouter.routes
          .home2({
            ...route.params,
            freshness: getCurrentFreshness(),
          })
          .replace();
      }, 0);
      return null;
    }
    return <HomeWrapper appContext={appContext} {...route.params} />;
  }
  if (route.name === 'listing2') {
    if (!route.params.freshness) {
      setTimeout(() => {
        typeRouter.routes
          .listing2({
            ...route.params,
            freshness: getCurrentFreshness(),
          })
          .replace();
      }, 0);
      return null;
    }
    return <ListingPage2Wrapper key="listing2" appContext={appContext} {...route.params} />;
  }
  if (route.name === 'reservationCheck') {
    return <ReservationCheckPageWrapper />;
  }
  if (route.name === 'myReservationsList') {
    return (
      <Layout>
        <ReservationsListPage />
      </Layout>
    );
  }
  if (route.name === 'reservationDetails') {
    if (!route.params.freshness) {
      setTimeout(() => {
        typeRouter.routes
          .reservationDetails({
            ...route.params,
            freshness: getCurrentFreshness(),
          })
          .replace();
      }, 0);
      return null;
    }
    if (route.params.vendorId === 'OT') {
      return <ReservationDetailOnlineTravel {...route.params} />;
    }
    const viewmodel = new ReservationDetailsPageViewModel(
      appContext,
      route.params.id,
      route.params.lastName,
      route.params.vendorId,
      route.params.freshness,
      route.params.showInsurance,
    );
    return (
      <ReservationDetailsPage
        viewmodel={viewmodel}
        // key={route.params.id}
      />
    );
  }

  return <h1> Not Found here </h1>;
});
