import { css, cx } from '@emotion/css';

import { FooterDesktop } from '^/components/Footer/Footer.Desktop';
import { HeaderDesktop } from '^/components/Header/Header.Desktop';
import { borders } from '^/constants/commonStyles';
import { LayoutProps } from '.';

export const LayoutDesktopStyles = {
  withMaxWidth: css`
    max-width: 1060px;
    margin-left: auto;
    margin-right: auto;
  `,
};

export const LayoutDesktop: React.FC<LayoutProps> = ({
  children,
  withoutFooter,
  withoutFooterMarginTop,
  withoutMaxWidth,
  withoutOverflowScroll,
  scrollAreaKey,
  withoutFooterTopLine,
  withoutNavigationFullRefresh,
  classOverrides,
}) => {
  return (
    <div
      id="Layout_Desktop_Root"
      className={cx(
        css`
          position: relative;
          width: 100%;
          height: 100%;
          overflow: hidden;
          overscroll-behavior: contain;
        `,
      )}
    >
      <div
        id="Header_Root"
        className={cx(
          css`
            width: 100%;
            top: 0;
            height: 72px;
            @media (max-width: 1280px) {
              padding-left: 24px;
              padding-right: 24px;
            }
          `,
          borders.bottom_border_1px,
        )}
      >
        <HeaderDesktop withoutFullRefresh={withoutNavigationFullRefresh} />
      </div>
      <div
        id="Layout_Desktop_Content"
        key={scrollAreaKey}
        style={{
          overflowY: withoutOverflowScroll ? 'hidden' : 'scroll',
          overflowX: 'hidden',
        }}
        className={[
          css`
            position: absolute;
            top: 72px;
            bottom: 0px;
            width: 100%;
            margin-left: auto;
            margin-right: auto;
          `,
        ].join(' ')}
      >
        <div
          id="Layout_Desktop_ContentContainer"
          className={cx(
            css`
              width: 100%;
              height: 100%;

              display: flex;
              flex-direction: column;
              justify-content: space-between;
            `,
          )}
        >
          <div
            id="Layout_Desktop_ChildrenContainer"
            className={cx(
              css`
                width: 100%;
              `,
              !withoutMaxWidth
                ? css`
                    max-width: 1060px;
                    margin-left: auto;
                    margin-right: auto;
                  `
                : '',
            )}
            style={{
              height: withoutOverflowScroll ? '100%' : undefined,
            }}
          >
            {children}
          </div>

          {/* Footer */}
          {!withoutFooter && (
            <>
              {!withoutFooterTopLine && (
                <div
                  className={css`
                    height: 1px;
                    border-top: 1px solid #f4f4f4;
                    margin-top: ${!withoutFooterMarginTop ? '56px' : '0'};
                  `}
                />
              )}
              <div
                className={css`
                  width: 100%;
                  margin-bottom: 56px;
                  background-color: ${classOverrides?.footerBgColor || '#fff'};
                `}
              >
                <div
                  className={cx(
                    css`
                      max-width: 1060px;
                      padding-left: 24px;
                      padding-right: 24px;
                      margin-left: auto;
                      margin-right: auto;

                      @media (max-width: 1280px) {
                        padding-left: 24px;
                        padding-right: 24px;
                      }
                    `,
                    !withoutMaxWidth ? LayoutDesktopStyles.withMaxWidth : '',
                  )}
                >
                  <FooterDesktop />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
