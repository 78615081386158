/* eslint-disable jsx-a11y/no-static-element-interactions */
import { css, cx } from '@emotion/css';
import { observer } from 'mobx-react-lite';

import { CarListingBottomSheetHeader } from '^/components/CarSearchBox/base/mobile/BottomSheet/CarListingBottomSheet.header';
import ReactPortal from '^/components/ReactPortal';
import { COLORS2, SPACING } from '^/constants/commonStyles';

import { ListingPage2MobileFilter } from '../ListingPage2.Mobile.Filter';
import { ListingPage2ViewModel } from '../../viewmodel/ListingPage2.viewmodel';

export const ListingPage2MobileFilterBottomSheet: React.FC<{
  viewmodel: ListingPage2ViewModel;
}> = observer(function ListingPage2MobileFilterBottomSheet({ viewmodel }) {
  return (
    <ReactPortal wrapperId="ListingPage-Filtering-BottomSheet">
      <div
        className={css`
          position: fixed;
          display: flex;
          flex-direction: column;

          width: 100%;
          height: 100%;

          background-color: rgba(34, 34, 34, 0.2);
        `}
      >
        <div
          id="AreaOutsideModal"
          className={cx(
            css`
              flex-grow: 1;
            `,
          )}
          onClick={() => {
            viewmodel.stepManager.clearStep();
          }}
        />
        <div
          id="fffff"
          className={css`
            width: 100%;
            height: calc(100% - 200px);

            border-top-right-radius: 10px;
            border-top-left-radius: 10px;

            background-color: ${COLORS2.main.MrtWhite};
          `}
        >
          <CarListingBottomSheetHeader
            title="필터"
            leftCornorBtnType="close"
            stepManager={viewmodel.carListingSearchBox.stepManager}
            overideOnLeftButtonClick={(e) => {
              viewmodel.stepManager.clearStep();
              e.preventDefault();
              return false;
            }}
          />
          <section
            className={cx(
              css`
                padding: 0 ${SPACING.spacing24};
                padding-top: 12px;

                /* position: relative; */
                height: calc(100% - 56px - 16px);
                overflow-y: scroll;
              `,
            )}
          >
            {viewmodel.allCarList.status === 'success' && (
              <ListingPage2MobileFilter
                loadedViewModel={viewmodel.allCarList.value}
                filterViewModel={viewmodel.filterViewModel}
              />
            )}
          </section>
        </div>
      </div>
    </ReactPortal>
  );
});
