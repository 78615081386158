import { makeAutoObservable } from 'mobx';

import { AppContext } from '^/AppContext/AppContext';
import { CalendarStateViewModel } from '^/components/Calendar/BasicCalendar/Calendar.state.viewmodel';
import { CalendarListViewModel } from '^/components/Calendar/BasicCalendar/components/List/Calendar.List.viewmodel';
import { CarListingSearchBoxInputViewModel } from '^/components/CarSearchBox/base/desktop/viewmodel/CarListingSearchBox.InputViewmodel';
import { CarListingSearchBoxViewModel } from '^/components/CarSearchBox/base/desktop/viewmodel/CarListingSearchBox.viewmodel';
import { typeRouter } from '^/typeRoute/typeRoutes';
import { getCurrentFreshness } from '^/types/__ResultType';
import { MobxInputValue } from '^/util/MobxInputValue';
import { MobxValue } from '^/util/MobxValue';

import { HomeRouteParams } from './Home.RouteParams';
import { HomeSearchBoxStepManager } from './HomeSearchBoxStepManager';
import { HomeSearchBoxStepManagerDesktop } from './HomeSearchBoxStepManager.Desktop';

export class HomeViewModel {
  constructor(
    public appContext: AppContext,
    public routeParams: MobxValue<HomeRouteParams>,
    public isMobile: boolean,
    public calendarsCount: number,

    public calendarPaginationOffset = new MobxInputValue(0),
    public freshness = getCurrentFreshness(),
    public calendarListViewModel = new CalendarListViewModel(calendarsCount),
    public calendarStateViewModel = new CalendarStateViewModel(calendarPaginationOffset),
    public carListingSearchBoxInputVM = appContext.carListingSearchBoxInput.value ||
      new CarListingSearchBoxInputViewModel(appContext, freshness, calendarStateViewModel),
    public carListingSearchBox = new CarListingSearchBoxViewModel(
      appContext,
      freshness,
      calendarListViewModel,
      carListingSearchBoxInputVM,
      isMobile
        ? new HomeSearchBoxStepManager(routeParams.value, carListingSearchBoxInputVM, (n) => {
            typeRouter.session.back(n);
          })
        : new HomeSearchBoxStepManagerDesktop(routeParams.value, carListingSearchBoxInputVM),
    ),
  ) {
    makeAutoObservable(this);
    if (appContext.carListingSearchBoxInput.value === null) {
      appContext.carListingSearchBoxInput.setValue(this.carListingSearchBox.i);
    }
  }
}
