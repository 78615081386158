/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';

import { css } from '@emotion/css';
import { observer } from 'mobx-react-lite';
import Sheet from 'react-modal-sheet';

import { CarListGroupKey } from '^/api2/getCarList/decodeCarListResponse';

import { ListingPage2MobileListGroupBottomSheetBodyArea } from './ListingPage2.Mobile.ListGroup.BottomSheet.BodyArea';
import { ListingPage2MobileListGroupBottomSheetHeaderArea } from './ListingPage2.Mobile.ListGroup.BottomSheet.HeaderArea';
import { ListingPage2ViewModel } from '../viewmodel/ListingPage2.viewmodel';

export const ListingPage2MobileListGroupBottomSheet2: React.FC<{
  viewmodel: ListingPage2ViewModel;
  carListGroupKey: CarListGroupKey | null;
  onClose: () => void;
}> = observer(function ListingPage2MobileListGroupBottomSheet2({
  viewmodel,
  carListGroupKey,
  onClose,
}) {
  if (viewmodel.allCarList.status !== 'success') {
    // FIXME: show error message / etc
    return null;
  }
  const loadedVM = viewmodel.allCarList.value;

  const item = loadedVM.filteredListAsMap.get(carListGroupKey || (' ' as CarListGroupKey));
  if (!item) {
    return null;
  }

  return (
    <Sheet isOpen={carListGroupKey !== null} onClose={() => onClose()} detent="content-height">
      <Sheet.Container
        style={{
          borderTopLeftRadius: 20,
          borderTopRightRadius: 20,
        }}
      >
        <Sheet.Header
          className={[
            // borders.DEBUG_greenBorderDashed,
          ].join(' ')}
        >
          <ListingPage2MobileListGroupBottomSheetHeaderArea item={item} />
        </Sheet.Header>
        <Sheet.Content>
          <div
            className={[
              css`
                width: 100%;
                height: 2px;
                background-color: #eaeaea;
              `,
            ].join(' ')}
          />

          <ListingPage2MobileListGroupBottomSheetBodyArea viewmodel={item} />
        </Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop
        onTap={() => onClose()}
        transition={{
          duration: 0,
        }}
        style={{
          backgroundColor: 'rgba(34,33,33,0.2)',
        }}
      />
    </Sheet>
  );
});
