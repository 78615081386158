export const CUSTOMER_SERVICE_BY_CHANNEL = {
  myrealtrip:
    '연중무휴 09:00~18:00 (점심시간 12:00~13:00)\n주말/공휴일 포함, 한국시간 기준\n유선상담 1670-8208\n※그 외 시간은 예약하신 현지 영업소로 연락해주세요.',
  waug: '유선상담 070-4353-5959\n카카오톡 플러스 친구 @waug\n AM 10:00 ~ PM 06:00(주말/공휴일 제외)\n*그 외 시간은 예약하신 현지 영업소로 연락해주세요.\n',
  zzimcar:
    '이메일 문의 cs@naturemobility.com\n유선상담 070-4348-3333\n카카오톡 플러스친구 @찜카\n상담시간 : 월~일 09:00~18:00\n점심시간 : 11:40~13:00\n주말 및 공휴일에는 상담이 지연될 수 있습니다.',
  tourvis:
    '평일 09:00~18:00, 주말 · 공휴일 휴무\n유선상담 1522-5149 (해외 82-2-2160-8595)\n※그 외 시간은 예약하신 현지 영업소로 연락해주세요.',
  trimo:
    '이메일 문의 info@trimo.ai\n\n*예약관련 문의는 예약하신 사이트로 연락해주세요.\n*픽업,반납 관련 문의는 예약하신 현지 영업소로 연락해주세요\n',
};
