import React from 'react';

import { observer } from 'mobx-react-lite';
import { useMediaQuery } from 'react-responsive';
import { LayoutMobile } from './Layout.Mobile';
import { LayoutDesktop } from './Layout.Desktop';

export interface LayoutProps extends React.PropsWithChildren {
  withoutFooter?: boolean;
  withoutFooterMarginTop?: boolean;
  withoutMaxWidth?: boolean;
  withoutOverflowScroll?: boolean;
  scrollAreaKey?: string;
  withoutFooterTopLine?: boolean;
  withoutNavigationFullRefresh?: boolean;
  classOverrides?: {
    layout2MobileContentWrapperBg?: string;
    footerBgColor?: string;
  };
}

export const Layout: React.FC<LayoutProps> = observer(function Layout(props) {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  if (isMobile) {
    return <LayoutMobile {...props} />;
  }

  return <LayoutDesktop {...props} />;
});

export default Layout;
