import { useRef } from 'react';

import { Tab } from '@headlessui/react';

import { font2 } from '^/constants/commonStyles';
import CancellationAndRefundOntraPOD from '^/pages/Booking/components/DriverDetails/components/CancellationAndRefund/CancellationAndRefund.Ontra.POD';
import ReservationConfirmMobile from '^/pages/Booking/onlinetravel/DriversDetails/components/ReservationConfirm.Mobile';
import { Horizon } from '^/pages/ReservationList/components/common/Horizon';
import { classNames } from '^/util';

import ReservationDetailBillMobile from './Bill/Bill.Mobile';
import DriverInfo from './DriverInfo/DriverInfo';

const ReservationDetailMobile = ({ resData }) => {
  const scrollAreaRef = useRef<HTMLElement>();
  if (scrollAreaRef.current === undefined) {
    const scrollArea = document.getElementById('Layout2_Mobile_Content');
    if (scrollArea) {
      scrollAreaRef.current = scrollArea;
    }
  }
  const scrollTop = () => scrollAreaRef.current?.scrollTo({ top: 0, behavior: 'smooth' });

  const totalOptionsFee = resData.option_info_list.reduce(
    (acc, option) => acc + option.foreign_price * option.quantity,
    0,
  );

  return (
    <>
      <Tab.Group>
        <div className="fixed top-[60px] z-10 w-full bg-white">
          <h2 className="z-10 w-full bg-white py-5 px-6 text-semihuge font-bold">예약정보</h2>
          <Tab.List className="flex justify-between">
            <Tab
              key="vehicle"
              className={({ selected }) =>
                classNames(
                  'pb-3 border-b w-[33%]',
                  selected ? 'border-primary-skyblue' : 'border-transparent',
                )
              }
              onClick={() => scrollTop()}
            >
              차량 및 일정
            </Tab>
            <Tab
              key="driver"
              className={({ selected }) =>
                classNames(
                  'pb-3  border-b w-[33%]',
                  selected ? 'border-primary-skyblue' : 'border-transparent',
                )
              }
              onClick={() => scrollTop()}
            >
              운전자 정보
            </Tab>
            <Tab
              key="fee"
              className={({ selected }) =>
                classNames(
                  'pb-3  border-b w-[33%]',
                  selected ? 'border-primary-skyblue' : 'border-transparent',
                )
              }
              onClick={() => scrollTop()}
            >
              요금 내역
            </Tab>
          </Tab.List>
          <hr className="h-px bg-secondary-gray-light" />
        </div>
        <Tab.Panels className="mt-[120px] p-[15px]">
          <Tab.Panel key="vehicle-info">
            <ReservationConfirmMobile data={resData} isResult={false} isDetail />
            {resData?.plan_info.cancel_policy.length > 0 && (
              <>
                <Horizon marginTop="1rem" />
                <h2 className={[font2.Headline1_Bold, 'mt-4'].join(' ')}>취소 및 환불규정</h2>
                <div className="mt-4 rounded-[10px] bg-secondary-gray-light p-4 text-default font-light leading-5 mobile:text-small">
                  <CancellationAndRefundOntraPOD cancelPolicy={resData.plan_info.cancel_policy} />
                </div>
              </>
            )}
          </Tab.Panel>
          <Tab.Panel key="driver-info">
            <DriverInfo data={resData.driver_info} />
          </Tab.Panel>
          <Tab.Panel key="bill-info">
            <ReservationDetailBillMobile data={resData} totalOptionsFee={totalOptionsFee} />
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </>
  );
};
export default ReservationDetailMobile;
