import { css } from '@emotion/css';

import { HoverToReveal } from '^/commonComponents/ui/HoverToReveal';
import {
  COLORS,
  SPACING,
  MARGINS,
  PADDINGS,
  COLORS2_AS_BACKGROUND,
  COLORS2_AS_FOREGROUND,
  font2,
} from '^/constants/commonStyles';
import AdditionalFeesOnlineTravel from '^/pages/Booking/onlinetravel/Bill/Bill.AdditionalFees';
import { formatKRWWonAmount } from '^/util/formatKRWWonAmount';
import { priceWithComma } from '^/util/priceFormat';

import BillCancelButton from './Bill.Booked.CancelButton';
import BillCanceled from './Bill.Canceled';
import { PODOnsitePaymentHoverMessage } from '../../onsiteInfoMessages';

import { CreditCardOnlyNotice } from './CreditCardOnlyNotice';

const ReservationDetailsBillDesktop = ({
  resData,
  totalOptionsFee,
  setShowCancelConfirmPopup,
  isCancelDisabled,
}) => {
  const { option_info_list: options, payment_info: payment } = resData;

  const resStatus = resData.booking_info.status;
  const rentalPrice = payment.basic_price + (payment.insurance_price || 0); // 대여금액 (basic_price + insurance_price)
  return (
    <>
      <h3 className={[font2.Title3].join(' ')}>요금 내역</h3>
      <div
        id="separator1"
        className={[
          css`
            margin-top: ${SPACING.spacing16};
            margin-bottom: ${SPACING.spacing16};
            background-color: ${COLORS.LIGHT_GREY};
            height: 2px;
          `,
        ].join(' ')}
      />
      <div className="flex flex-col">
        <div className="flex flex-row justify-between">
          <p className={[font2.Headline1_Regular].join(' ')}>대여 요금</p>
          <p className={[font2.Headline1_Bold].join(' ')}>
            {priceWithComma(rentalPrice)} {payment?.foreign_currency}
          </p>
        </div>
        {options.length > 0 ? (
          <div
            className={[
              // 'flex flex-col gap-1.5',
              // borders.DEBUG_greenBorderDashed,
              MARGINS.TOP.spacing12,
            ].join(' ')}
          >
            <AdditionalFeesOnlineTravel
              totalOptionsFee={totalOptionsFee}
              options={options}
              payment={payment}
            />
          </div>
        ) : (
          <></>
        )}
        <div
          id="separator"
          className={[
            css`
              margin-top: ${SPACING.spacing16};
              margin-bottom: ${SPACING.spacing16};
              background-color: ${COLORS.LIGHT_GREY};
              height: 2px;
            `,
          ].join(' ')}
        />
        <div
          className={[
            'flex flex-row justify-between',
            // borders.DEBUG_goldDashed,
            css`
              position: relative;
            `,
          ].join(' ')}
        >
          <div
            className={[
              font2.Headline1_Bold,
              // borders.DEBUG_goldDashed,
              css`
                display: flex;
                align-items: center;
                justify-items: center;
                height: fit-content;
                gap: 6px;
              `,
            ].join(' ')}
          >
            <span>현장 결제 예상금액</span>
            <HoverToReveal
              toReveal={
                <div
                  className={[
                    font2.Caption1_Light,
                    PADDINGS.TOP_AND_BOTTOM.spacing16,
                    COLORS2_AS_BACKGROUND.sub.MrtGray3,
                    css`
                      position: absolute;
                      left: 0;
                      right: 0;
                      top: 70%;
                      padding-left: 10px;
                      padding-right: 10px;
                      border-radius: 10px;
                    `,
                  ].join(' ')}
                >
                  {PODOnsitePaymentHoverMessage}
                </div>
              }
            />
          </div>
          <div>
            <div className="flex flex-col items-end gap-1.5">
              <p className={[font2.Title2, COLORS2_AS_FOREGROUND.main.MrtBlack].join(' ')}>
                {priceWithComma(payment?.foreign_total_price)}
                &nbsp;
                {payment?.foreign_currency}
              </p>
              <p className={[font2.Caption1_Light, COLORS2_AS_FOREGROUND.sub.MrtGray1].join(' ')}>
                약 {formatKRWWonAmount(payment?.total_price)} 원
              </p>
            </div>
          </div>
        </div>
        {resStatus == 'booked' && (
          <div className="mt-[15px]">
            <BillCancelButton
              data={resData}
              setShowCancelConfirmPopup={setShowCancelConfirmPopup}
              isCancelDisabled={isCancelDisabled}
            />
          </div>
        )}
        {resStatus == 'canceled' && <BillCanceled data={resData} />}
        {resStatus == 'booked' && payment?.card_flag ? (
          <p className="mt-4 text-right text-small font-bold">
            <CreditCardOnlyNotice />
          </p>
        ) : null}
      </div>
    </>
  );
};

export default ReservationDetailsBillDesktop;
