import { css } from '@emotion/css';
import { observer } from 'mobx-react-lite';

import { SPACING, COLORS2, font } from '^/constants/commonStyles';
import CancellationAndRefundHertzPOD from '^/pages/Booking/components/DriverDetails/components/CancellationAndRefund/CancellationAndRefund.Hertz.POD';
import CancellationAndRefundHertzPPD from '^/pages/Booking/components/DriverDetails/components/CancellationAndRefund/CancellationAndRefund.Hertz.PPD';
import { getTermsAndConditionCancellationRichContent } from '^/pages/Booking/renewal/components/TermsAndConditions/TermsAndConditionCancellation.Rich';
import { getTermsAndConditionCancellationTumonContent } from '^/pages/Booking/renewal/components/TermsAndConditions/TermsAndConditionCancellation.Tumon';

import { ReservationDetailsPageViewModel } from '../ReservationDetailsPage.viewmodel';

import { ReservationDetailsBill } from './components/ReservationDetailDesktop.bill';
import { ReservationDetailsDriverInfo } from './components/ReservationDetailDesktop.DriverInfo';
import { ReservationDetailsVehicleScheduleInfo } from './components/ReservationDetailDesktop.VehicleScheduleInfo';
import { ReservationStatusBadge } from './ReservationStatusBadge';

export const ReservationDetailPageDesktop: React.FC<{
  viewmodel: ReservationDetailsPageViewModel;
  onClickCancelReservation: () => void;
}> = observer(function ReservationDetailPageDesktop({ viewmodel, onClickCancelReservation }) {
  const getCancellationTerms = () => {
    if (viewmodel.vendorId === 'RC') {
      return getTermsAndConditionCancellationRichContent();
    }
    if (viewmodel.vendorId === 'TM') {
      return getTermsAndConditionCancellationTumonContent();
    }
    if (
      viewmodel.reservationDetail.status === 'success' &&
      viewmodel.reservationDetail.value.pay_type === 'PPD'
    ) {
      return <CancellationAndRefundHertzPPD />;
    }
    return <CancellationAndRefundHertzPOD />;
  };

  if (viewmodel.reservationDetail.status === 'success') {
    return (
      <div
        id="ReservationDetailsPageDesktopRoot"
        className={[
          css`
            height: 100%;
            @media (max-width: 1280px) {
              padding-left: 24px;
              padding-right: 24px;
            }
            padding-left: calc(50% - 660px);
            padding-right: calc(50% - 660px);
            display: grid;
            gap: ${SPACING.spacing24};
            padding-top: 60px;
            grid-template-columns: 1fr max-content;
            grid-template-rows: 1fr min-content;
            grid-template-areas:
              'content floatingbill'
              'footer footer';
          `,
        ].join(' ')}
      >
        <div
          className={[
            css`
              grid-area: content;
            `,
          ].join(' ')}
        >
          <div
            className={css`
              display: flex;
              justify-content: space-between;
              width: 100%;
            `}
          >
            <h1 className={font.Title1}>예약 내역 상세</h1>
            <ReservationStatusBadge status={viewmodel.reservationDetail.value.res_status} />
          </div>

          <h2
            className={[
              font.Title2,
              css`
                margin-top: 60px;
              `,
            ].join(' ')}
          >
            차량 및 일정
          </h2>
          <ReservationDetailsVehicleScheduleInfo data={viewmodel.reservationDetail.value} />
          <div
            className={[
              css`
                margin-top: ${SPACING.spacing48};
                background-color: ${COLORS2.sub.MrtGray2};
                height: 1px;
                width: 100%;
              `,
            ].join(' ')}
          />
          <h2
            className={[
              font.Title2,
              css`
                margin-top: ${SPACING.spacing48};
              `,
            ].join(' ')}
          >
            운전자 정보
          </h2>
          <ReservationDetailsDriverInfo data={viewmodel.reservationDetail.value} />
          <div
            className={[
              css`
                margin-top: ${SPACING.spacing48};
                background-color: ${COLORS2.sub.MrtGray2};
                height: 1px;
                width: 100%;
              `,
            ].join(' ')}
          />
          <h2
            className={[
              font.Title2,
              css`
                margin-top: ${SPACING.spacing48};
              `,
            ].join(' ')}
          >
            취소 및 환불규정
          </h2>
          <div className="mt-4 rounded-[10px] bg-secondary-gray-light p-4 text-default font-light leading-5 mobile:text-small">
            {getCancellationTerms()}
          </div>
        </div>
        <div
          className={[
            css`
              position: sticky;
              top: 0px;
              height: fit-content;
              margin-bottom: 380px;
              grid-area: floatingbill;

              box-shadow: 5px 5px 15px 0 rgba(0, 0, 0, 0.1);
              border-radius: 10px;
              background-color: white;

              padding-top: ${SPACING.spacing24};
              padding-left: ${SPACING.spacing24};
              padding-right: ${SPACING.spacing24};
              padding-bottom: ${SPACING.spacing16};
              width: 424px;
            `,
          ].join(' ')}
        >
          <ReservationDetailsBill
            data={viewmodel.reservationDetail.value}
            onClickCancelReservation={() => {
              onClickCancelReservation();
            }}
          />
        </div>
      </div>
    );
  }

  return null;
});
