/* eslint-disable operator-linebreak */
import React from 'react';

import { css, cx } from '@emotion/css';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import {
  SPACING,
  MARGINS,
  PADDINGS,
  COLORS2_AS_FOREGROUND,
  font2,
  COLORS,
} from '^/constants/commonStyles';

import { translateServices } from '../common/translateServices';
import { changeToUpperCaseOfWordOnlyFistAlpahbet, deleteGrade } from '../common/utils';
import { ListingPage2CardViewModel } from '../viewmodel/ListingPage2.card.viewmodel';

import { marginRight72Until830px } from './ListingPage2.Desktop.Card.styles';

export const ListingPage2DesktopCardSummary: React.FC<{
  viewmodel: ListingPage2CardViewModel;
}> = observer(function ListingPage2DesktopCardSummary({ viewmodel }) {
  const { t } = useTranslation();
  const { data, lowestPricePayment } = viewmodel;

  const convertedModelName =
    data.modelName && deleteGrade(changeToUpperCaseOfWordOnlyFistAlpahbet(data.modelName));

  const carSize = data.vehType.size;
  const carType = data.vehType.type;

  const isDiscounted = lowestPricePayment?.payment.isDiscounted;
  const currencyCode = lowestPricePayment?.payment.foreignCurrencyCode;

  const discountForeign = Number(lowestPricePayment?.payment.discountForeign);
  const foreignPrice = Number(lowestPricePayment?.payment.foreignCurrency);
  const finalForeignPrice = isDiscounted ? Math.ceil(foreignPrice - discountForeign) : foreignPrice;

  const discountDomestic = Number(lowestPricePayment?.payment.discountDomestic);
  const domesticPrice = Number(lowestPricePayment?.payment.domesticPrice);
  const finalDomesticPrice = isDiscounted ? domesticPrice - discountDomestic : domesticPrice;

  return (
    <div
      id="ListingPageDesktopCardSummary_root"
      className={[
        css`
          display: flex;
          align-items: center;
        `,
        PADDINGS.TOP_AND_BOTTOM.spacing24,
        // borders.DEBUG_orangeBorderDashed,
      ].join(' ')}
    >
      <img
        alt="carImage"
        src={data.carImageUrl}
        className={[
          css`
            width: 220px;
            /* height: 140px; */
            margin-left: clamp(10px, 70px - ((900px - 100vw) * 0.5), 70px);
          `,
          // borders.DEBUG_greenBorderDashed,
        ].join(' ')}
        onError={(e) => {
          e.currentTarget.src = '/NOT_FOUND_CAR.png';
        }}
      />
      {/* CarListing2_Description */}
      <div
        id="CarListing2_Description"
        className={
          css`
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-left: clamp(10px, 50px - ((800px - 100vw) * 0.5), 50px);
            width: 513px;
            padding-right: 10px;
          `
          // MARGINS.LEFT.spacing48,
          // borders.DEBUG_greenBorderDashed,
        }
      >
        <h2
          className={[
            font2.Title2,
            css`
              word-break: keep-all;
            `,
          ].join(' ')}
        >
          {`[${t(carSize)}] ${convertedModelName} ${data.orSimilarCar ? '또는 동급차량' : ''}`}
        </h2>
        <h4
          className={css`
            color: #1e1e1e;
            font-size: 18px;
            font-weight: 400;
            line-height: 18px; /* 100% */
            margin-top: 10px;
          `}
        >
          {translateServices(data.services, t)}
        </h4>
        {/* Badges */}
        <div
          className={css`
            display: flex;
            align-items: center;
            gap: 5px;
            margin-top: 28px;
          `}
        >
          {data.badges?.map((badge) => (
            <span
              className={css`
                border-radius: 3px;
                background-color: ${COLORS.BLUE_200};
                font-size: 12px;
                color: ${COLORS.BLUE_500};
                padding: 0 6px;
              `}
            >
              {badge}
            </span>
          ))}
        </div>
      </div>
      <div
        className={[
          css`
            /* move to rightside by filling-in left margin */
            margin-left: auto;
            display: flex;
            align-items: end;
            flex-direction: column;
            width: 190px;
          `,
          marginRight72Until830px,
          // borders.DEBUG_greenBorderDashed,
        ].join(' ')}
      >
        <h3
          className={[
            COLORS2_AS_FOREGROUND.sub.MrtGray1,
            font2.Caption1_Light,
            isDiscounted &&
              css`
                margin-top: 12px;
              `,
          ].join(' ')}
        >
          {viewmodel.groupedByVendors.keys().length()}개 렌트카 업체
        </h3>
        {isDiscounted && (
          <h4
            className={css`
              margin-top: 12px;
              color: #717171;
              font-weight: 400;
              line-height: 112.5%;
              text-decoration: line-through;
            `}
          >
            최저{' '}
            <span
              className={css`
                font-weight: 700;
                line-height: 112.5%;
              `}
            >
              {foreignPrice.toLocaleString()} {currencyCode}
            </span>
          </h4>
        )}
        <h1
          className={[
            font2.Title1,
            isDiscounted && COLORS2_AS_FOREGROUND.main.GuamBlue1,
            isDiscounted &&
              css`
                line-height: 128.6%;
              `,
            MARGINS.TOP.spacing12,
            css`
              text-wrap: nowrap;
            `,
          ].join(' ')}
        >
          <span className={[font2.Caption1_Bold, MARGINS.RIGHT.spacing8].join(' ')}>최저</span>
          {finalForeignPrice.toLocaleString()} {currencyCode}
        </h1>
        <h2
          className={[
            COLORS2_AS_FOREGROUND.sub.MrtGray1,
            font2.Body1_Regular,
            isDiscounted &&
              css`
                line-height: 90%;
              `,
          ].join(' ')}
        >
          약 {finalDomesticPrice?.toLocaleString()} 원
        </h2>
      </div>
    </div>
  );
});
