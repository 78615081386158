import dayjs, { Dayjs } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import duration from 'dayjs/plugin/duration';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);
dayjs.extend(customParseFormat);
dayjs.extend(duration);

export function dateWithHyphen(target) {
  if (!target) {
    return target;
  }
  const date = target.replace(/[^0-9]/g, '').trim();

  if (date.length < 9) {
    return date.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3');
  }
  if (date.length < 13) {
    return date.replace(/(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})/, '$1-$2-$3 $4:$5');
  }
  return date.replace(/(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/, '$1-$2-$3 $4:$5:$6');
}

export function formatDateWithTimezone(
  dateString: string,
  returnType: 'string' | 'date' = 'string',
): string | Date {
  // 정규식을 사용하여 'yyyy-mm-ddThh:mm:ss' 형식인지 확인
  const regexWithTimezone = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/;
  if (!regexWithTimezone.test(dateWithHyphen(dateString))) {
    // 'yyyy-mm-ddThh:mm:ss' 형식이 아닌 경우 변환
    const date = new Date(dateWithHyphen(dateString));
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    const hours = ('0' + date.getHours()).slice(-2);
    const minutes = ('0' + date.getMinutes()).slice(-2);
    const seconds = ('0' + date.getSeconds()).slice(-2);
    dateString = year + '-' + month + '-' + day + 'T' + hours + ':' + minutes + ':' + seconds;
  }
  if (returnType === 'string') {
    return dateString;
  } else if (returnType === 'date') {
    return new Date(dateString);
  } else {
    throw new Error('유효하지 않은 반환 타입입니다.');
  }
}

export const formatDateTimeForOntra = (datetime: string) => {
  const dateTimeArr = datetime.split('T');
  const date = dateTimeArr[0];
  const time = dateTimeArr[1];

  return `${date.replaceAll('-', '')}${time.replaceAll(':', '').slice(0, 4)}`;
};
/**
 *
 * @param dateString 날짜 문자열
 * @param formatString 날짜 문자열을 변환할 포맷 문자열
 * @param timezone 타임존 (default: 'UTC')
 * @returns 변환된 날짜 문자열
 */
export const formatDateString = (dateString: string, formatString: string, timezone?: string) => {
  if (!dateString) {
    return null;
  }
  const parsedDate = dayjs.utc(dateString);
  if (parsedDate.isValid()) {
    if (timezone) {
      return parsedDate.tz(timezone).format(formatString);
    }
    return parsedDate.format(formatString);
  }

  throw new Error('Invalid date format');
};

export const getDurationString = (start: string | Date | Dayjs, end: string | Date | Dayjs) => {
  const duration = getDuration(start, end);
  return durationToText(duration);
};

export const getDuration = (start: string | Date | Dayjs, end: string | Date | Dayjs) => {
  return dayjs.duration(dayjs(end).diff(dayjs(start)));
};

export const durationToText = (d: duration.Duration) => {
  let period = '';

  if (d.months()) period += `${d.months()}개월`;
  if (d.days()) period += ` ${d.days()}일`;
  if (d.hours()) period += ` ${d.hours()}시간`;
  if (d.minutes()) period += ` ${d.minutes()}분`;

  return period.trim();
};
