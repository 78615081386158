import { useMediaQuery } from 'react-responsive';
import { useRecoilValue } from 'recoil';

import { font2 } from '^/constants/commonStyles';
import { authAtom } from '^/recoil/atoms/auth';
import { typeRouter } from '^/typeRoute/typeRoutes';

import ReservationConfirm from '../Booking/onlinetravel/DriversDetails/components/ReservationConfirm';
import { LayoutDesktopStyles } from '^/components/Layout/Layout.Desktop';

function gotoMyReservationByRefresh() {
  const myReservationPath = typeRouter.routes.myReservationsList().href;
  window.location.href = myReservationPath;
}
function goToHomeByRefresh() {
  window.location.href = window.location.origin;
}

const ReservationResultOnlineTravel = ({ reservationData }) => {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const isAuth = useRecoilValue(authAtom);

  // useEffect(() => {
  //   sendTrackingLog({
  //     action: actions.RESERVATION_RESULT.ENTER,
  //   });
  // }, []);

  if (!reservationData) {
    reservationData = sessionStorage.getItem('_BOOKING_');
  }
  return (
    <div
      className={[
        // borders.DEBUG_blueBorderDashed,
      ].join(' ')}
    >
      <div
        className={[
          LayoutDesktopStyles.withMaxWidth,
          'relative flex flex-col mobile:mx-6 trimo-screen:mx-auto',
          // borders.DEBUG_blueBorderDashed,
        ].join(' ')}
      >
        <div className="mt-[50px] mb-6 flex items-center gap-3  tablet-max:my-5">
          <h1
            className={[
              isMobile ? 'text-middlegiant font-bold tablet-max:text-semihuge' : font2.Title1,
            ].join(' ')}
          >
            예약이 완료되었습니다!
          </h1>
        </div>
        <div className="m-auto w-[872px] mobile:w-[100%]">
          <ReservationConfirm
            data={reservationData.bookingInfo}
            options={reservationData.bookingInfo.option_info_list}
            isTitle={false}
            isResult
          />
          {isMobile ? (
            <div className="mt-5 flex w-full items-center justify-between gap-[15px]">
              <button
                onClick={() => {
                  // sendTrackingLog({
                  //   action: actions.RESERVATION_RESULT.CLICK_HOME,
                  // });
                  goToHomeByRefresh();
                }}
                className="basis-1/2 rounded-trimo-5 border border-neutral-100 bg-white font-bold text-primary-gray hover:bg-primary-skyblue hover:text-primary-white mobile:p-[15px] tablet:py-4 tablet:px-6"
              >
                홈으로
              </button>
              <button
                onClick={() => {
                  // sendTrackingLog({
                  //   action: actions.RESERVATION_RESULT.CLICK_RESERVATION_DETAIL,
                  // });
                  gotoMyReservationByRefresh();
                }}
                disabled={!isAuth}
                className="basis-1/2 whitespace-nowrap rounded-trimo-5 bg-primary-skyblue font-bold text-primary-white hover:bg-primary-skyblue hover:text-primary-skyblue mobile:p-[15px] tablet:bg-trimo-shadow tablet:py-4 tablet:px-6"
              >
                예약 내역 확인하기
              </button>
            </div>
          ) : (
            <div className="mt-4 flex w-full items-center justify-between mobile:mt-5 mobile:gap-[15px] tablet:gap-6">
              <button
                onClick={() => goToHomeByRefresh()}
                className={[
                  'basis-1/2 tablet:py-4.5 tablet:px-6 bg-trimo-shadow rounded-trimo-5 hover:text-primary-white hover:bg-primary-skyblue',
                  font2.Body1_Bold,
                ].join(' ')}
              >
                홈으로
              </button>
              <button
                onClick={gotoMyReservationByRefresh}
                disabled={!isAuth}
                className={[
                  'basis-1/2 tablet:py-4.5 tablet:px-6 bg-primary-skyblue rounded-trimo-5 text-primary-white hover:text-primary-white hover:bg-primary-skyblue whitespace-nowrap',
                  font2.Body1_Bold,
                ].join(' ')}
              >
                예약 내역 확인하기
              </button>
            </div>
          )}
        </div>
      </div>
      {!isMobile ? (
        <>
          {/* <div
            className={[
              COLORS2_AS_BACKGROUND.sub.MrtGray3,
              COLORS2_AS_FOREGROUND.sub.MrtGray3,
              MARGINS.TOP.spacing120,
              css`
                width: 100%;
                height: 2px;
              `,
            ].join(' ')}
          />
          <div
            className={[
              'relative flex flex-col mobile:mx-6 trimo-screen:mx-auto',
              // borders.DEBUG_greenBorderDashed,
            ].join(' ')}
          >
            <Footer2 />
          </div> */}
        </>
      ) : (
        <></>
        // <Footer3Mobile
        //   stylesOverride={{
        //     FooterRoot: [
        //       Footer3MobileDefaultStyles.FooterRoot,
        //       MARGINS.TOP.spacing48,
        //       // borders.DEBUG_greenBorderDashed,
        //     ].join(' '),
        //   }}
        // />
      )}
    </div>
  );
};

export default ReservationResultOnlineTravel;
