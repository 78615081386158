import React, { useEffect, useMemo } from 'react';

import { observer } from 'mobx-react-lite';
import { useMediaQuery } from 'react-responsive';
import { isOntra } from '^/api2/getRentalCarOfficeLocationsByCity/OnlineTravelOfficeLocations.constants';
import { AppContext } from '^/AppContext/AppContext';
import { AlertPopup } from '^/components/Popup/AlertPopup/AlertPopup';
import { ListingPage2RouteParams } from './ListingPage2RouteParams';
import { ListingPage2ViewModel } from './viewmodel/ListingPage2.viewmodel';
import { ListingPageOnlineTravelViewModel } from './onlinetravel/viewmodel/ListingPage.OnlineTravel.viewmodel';
import { ListingPageOnlineTravelWrapper } from './onlinetravel/ListingPage.OnlineTravel.Wrapper';
import { ListingPage2Mobile } from './mobile/ListingPage2Mobile';
import { ListingPageDesktop } from './desktop/ListingPage.Desktop';

export const ListingPage2Wrapper: React.FC<
  {
    appContext: AppContext;
  } & ListingPage2RouteParams
> = observer(function ListingPage2Wrapper(props) {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const { appContext, ...routeParams } = props;
  const { steps } = routeParams;

  const viewmodel = useMemo(
    () =>
      new ListingPage2ViewModel(
        appContext,
        routeParams,
        13,
        isMobile,
        undefined,
        routeParams.freshness,
      ),
    [
      routeParams.pLocationId,
      routeParams.rLocationId,
      routeParams.pDatetime,
      routeParams.rDatetime,
      routeParams.birthday,
      routeParams.freshness,
    ],
  );

  useEffect(() => {
    viewmodel.carListingSearchBox.stepManager.updateRouteParams(routeParams);
  }, [steps]);

  useEffect(() => {
    // save listing path for booking error
    sessionStorage.setItem('listingSearchParam', new URL(window.location.href).search);
  }, [routeParams.pLocationId, routeParams.rLocationId, routeParams.pDatetime, routeParams.rDatetime, routeParams.birthday]);

  const isOnlineTravel = isOntra(routeParams.pCityCode);

  if (isOnlineTravel) {
    const onlineTravelVM = new ListingPageOnlineTravelViewModel(
      appContext,
      routeParams,
      13,
      isMobile,
      undefined,
      routeParams.freshness,
    );
    return <ListingPageOnlineTravelWrapper viewmodel={onlineTravelVM} />;
  }

  if (isMobile) {
    return (
      <>
        <AlertPopup
          visible={viewmodel.carListingSearchBox.showDifferentLocationAlert}
          title="해당 지역은 대여/반납 장소를 다르게 지정할 수 없습니다."
          closeText="닫기"
          handleClose={() => viewmodel.carListingSearchBox.showDifferentLocationAlert.set(false)}
          isMobile
        />
        <ListingPage2Mobile viewmodel={viewmodel} />
      </>
    );
  }

  return (
    <>
      <AlertPopup
        visible={viewmodel.carListingSearchBox.showDifferentLocationAlert}
        title="해당 지역은 대여/반납 장소를 다르게 지정할 수 없습니다."
        closeText="닫기"
        handleClose={() => viewmodel.carListingSearchBox.showDifferentLocationAlert.set(false)}
        isMobile
      />
      <ListingPageDesktop viewmodel={viewmodel} />
    </>
  );
});
