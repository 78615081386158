/* eslint-disable operator-linebreak */
/* eslint-disable @typescript-eslint/indent */
import { makeAutoObservable } from 'mobx';

import { OnlineTravelOffices } from '^/api2/getRentalCarOfficeLocationsByCity/OnlineTravelOfficeLocations.constants';
import { AppContext } from '^/AppContext/AppContext';
import { CalendarStateViewModel } from '^/components/Calendar/BasicCalendar/Calendar.state.viewmodel';
import { dayTypeFromString } from '^/components/Calendar/BasicCalendar/components/Day/DayType';
import { CalendarListViewModel } from '^/components/Calendar/BasicCalendar/components/List/Calendar.List.viewmodel';
import { CarListingSearchBoxInputViewModel } from '^/components/CarSearchBox/base/desktop/viewmodel/CarListingSearchBox.InputViewmodel';
import { CarListingSearchBoxViewModel } from '^/components/CarSearchBox/base/desktop/viewmodel/CarListingSearchBox.viewmodel';
import { typeRouter } from '^/typeRoute/typeRoutes';
import { getCurrentFreshness } from '^/types/__ResultType';
import { MobxInputValue } from '^/util/MobxInputValue';
import { getSessionId, getUUID } from '^/util/user';

import { ListingPage2FilterViewModel } from '../../viewmodel/ListingPage2.FilterViewModel';
import { ListingSearchBoxStepManager } from '../../viewmodel/ListingSearchBoxStepManager';
import { ListingSearchBoxStepManagerDesktop } from '../../viewmodel/ListingSearchBoxStepManager.desktop';
import { ListingPage2RouteParams } from '../../ListingPage2RouteParams';

import getConfig from '^config';
import { decodeDatetimeWithoutTimezone } from '^/types/__BrandedDateTimeTypes';

export class ListingPageOnlineTravelViewModel {
  constructor(
    public appContext: AppContext,
    public routeParamsValues: ListingPage2RouteParams,
    public calendarsCount: number,
    public isMobile: boolean,
    public calendarPaginationOffset = new MobxInputValue(0),
    public freshness = getCurrentFreshness(),
    public filterViewModel = new ListingPage2FilterViewModel(),
    public calendarStateViewModel = new CalendarStateViewModel(
      calendarPaginationOffset,
      undefined,
      undefined,
      {
        start: dayTypeFromString(routeParamsValues.pDatetime),
        end: dayTypeFromString(routeParamsValues.rDatetime),
      },
    ),
    public calendarListViewModel = new CalendarListViewModel(calendarsCount),
    public carListingSearchBoxInputVm = appContext.carListingSearchBoxInput.value ||
      new CarListingSearchBoxInputViewModel(
        appContext,
        freshness,
        calendarStateViewModel,
        routeParamsValues,
      ),
    public stepManager = isMobile
      ? new ListingSearchBoxStepManager(routeParamsValues, carListingSearchBoxInputVm, (n) => {
          typeRouter.session.back(n);
        })
      : new ListingSearchBoxStepManagerDesktop(carListingSearchBoxInputVm),
    public carListingSearchBox = new CarListingSearchBoxViewModel(
      appContext,
      freshness,
      calendarListViewModel,
      carListingSearchBoxInputVm,
      stepManager,
    ),
  ) {
    makeAutoObservable(this);
    if (appContext.carListingSearchBoxInput.value === null) {
      appContext.carListingSearchBoxInput.setValue(this.carListingSearchBox.i);
    }
  }

  setRouteParams(routeParamsValues: ListingPage2RouteParams) {
    if (
      this.routeParamsValues.pDatetime !== routeParamsValues.pDatetime ||
      this.routeParamsValues.rDatetime !== routeParamsValues.rDatetime
    ) {
      this.calendarStateViewModel.selection = {
        start: dayTypeFromString(routeParamsValues.pDatetime),
        end: dayTypeFromString(routeParamsValues.rDatetime),
      };
    }
    this.routeParamsValues = {
      ...this.routeParamsValues,
      ...routeParamsValues,
    };
  }

  get allCarList() {
    const r = this.routeParamsValues;

    const decodedPickupDatetime = decodeDatetimeWithoutTimezone(r.pDatetime);
    const { dayStr: pickupDayStr, hour: pickupHour, minute: pickupMinute } = decodedPickupDatetime;
    const pickupDatetime = `${pickupDayStr.replaceAll('-', '')}${pickupHour}${pickupMinute}`;

    const decodedReturnDatetime = decodeDatetimeWithoutTimezone(r.rDatetime);
    const { dayStr: returnDayStr, hour: returnHour, minute: returnMinute } = decodedReturnDatetime;
    const returnDatetime = `${returnDayStr.replaceAll('-', '')}${returnHour}${returnMinute}`;

    const argBody = {
      pickup_datetime: pickupDatetime,
      return_datetime: returnDatetime,
      pickup_city: OnlineTravelOffices.find(
        (office) => office.location_id === (r.pLocationId as string),
      )?.city_code,
      pickup_location: r.pLocationId,
      birth_day: r.birthday,
      uuid: getUUID(),
      session_id: getSessionId(),
      // is_test: false, // NOTE: FOR ONLINE_TRAVEL_PROD_TESTING,
      is_test: getConfig().CONFIG_NAME === 'dev', // NOTE: FOR DEV TESTING,
    };

    return this.appContext.getOTCarListCore.getList(
      {
        path: '/search/cars-onlineTravel',
        method: 'post',
        body: argBody,
      },
      this.freshness,
    );
  }
}
