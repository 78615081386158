import { css } from '@emotion/css';

import { MARGINS, COLORS, COLORS2_AS_FOREGROUND, font2 } from '^/constants/commonStyles';
import '../../desktop/components/ReservationDetailDesktop.commonStuff';
import { formatDateString } from '^/util/dateFormat';

import { useMediaQuery } from 'react-responsive';

const BillCanceled = ({ data }) => {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const cancelTime = formatDateString(
    data.booking_info.cancel_datetime,
    'YYYY.MM.DD HH:mm (z)',
    'Asia/Seoul',
  );

  return (
    <>
      {isMobile ? (
        ''
      ) : (
        <div
          id="separator3"
          className={[
            MARGINS.TOP.spacing16,
            css`
              background-color: ${COLORS.LIGHT_GREY};
              height: 2px;
            `,
          ].join(' ')}
        />
      )}
      <div className="mt-4 flex items-center justify-between">
        <p
          className={[
            font2.Headline1_Regular,
            COLORS2_AS_FOREGROUND.main.MrtBlack,
            'mobile:text-big',
          ].join(' ')}
        >
          취소 일시
        </p>
        <p className={[font2.Headline1_Bold, COLORS2_AS_FOREGROUND.main.MrtBlack].join(' ')}>
          {cancelTime}
        </p>
      </div>
    </>
  );
};

export default BillCanceled;
