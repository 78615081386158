import { z } from 'zod';

import { asFailedPromise, asSuccessPromise } from '^/types/__ResultType';
import { createRedisKey } from '^/util/createKey';
import { sleepMs } from '^/util/sleepMs';

import { defineResponseWithData } from '../DefineResponse';
import { zodValidateAxiosResp } from '../zodValidate';

import { GetCarListArgsType } from './ArgsType';
import { decodeCarListResponse } from './decodeCarListResponse';
import { RawGetCarListSuccessBodyItem } from './getCarList.successtype';
import { getCarListStep1 } from './step1_cars_post';
import { getCarListStep2 } from './step2_loading_get';

const CarListRawResponseType = defineResponseWithData(RawGetCarListSuccessBodyItem.array(), 200);
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type CarListRawResponseType = z.infer<typeof CarListRawResponseType>;

const validator = zodValidateAxiosResp(CarListRawResponseType, (err) => {
  console.error('getCarListing zodError', err);
});
export async function getCarListActualAPI(args: GetCarListArgsType) {
  const key = createRedisKey([
    args.agent_name,
    args.pickup_city,
    args.pickup_location,
    args.pickup_datetime,
    args.return_city,
    args.return_location,
    args.return_datetime,
  ]);

  let a = await getCarListStep1({
    ...args,
    key,
  });
  if (a.status !== 200 && a.status !== 201) {
    a = await getCarListStep1({
      ...args,
      key,
    });
  }
  if (a.status !== 200 && a.status !== 201) {
    return asFailedPromise({
      reason: 'GetCarListStep1Failed',
    } as const);
  }
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < 10; i++) {
    // eslint-disable-next-line no-await-in-loop
    await sleepMs(200);
    // eslint-disable-next-line no-await-in-loop
    const rawResponse = await getCarListStep2({ key });
    const validated = validator(rawResponse);

    if (validated.data.status !== 200) {
      return asFailedPromise({
        reason: 'HTTPError',
        err: rawResponse.data,
        rawResponse,
      } as const);
    }
    if (validated.data.data.length > 0) {
      return asSuccessPromise(decodeCarListResponse(validated.data));
    }
    // eslint-disable-next-line no-await-in-loop
    await sleepMs(500);
  }
  return asFailedPromise({
    reason: 'Timeout',
  } as const);
}

export const getCarListAPI = getCarListActualAPI;
