/* eslint-disable no-underscore-dangle */
import React, { RefObject } from 'react';

import { css, cx } from '@emotion/css';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { CheckboxesWithAllToggle } from '^/commonComponents/inputs/CheckboxesWithAllToggle';
import { hideScrollBar, SPACING } from '^/constants/commonStyles';
import { INITIAL_VEHCLASS_ZOD } from '^/constants/envConfig';
import { ListingPage2FilterViewModel } from '^/pages/Listing/viewmodel/ListingPage2.FilterViewModel';

import { CarClassChipsItem } from './CarClassChips.item';

export const CarClassChips: React.FC<{
  filterViewModel: ListingPage2FilterViewModel;
  carClassChipsScrollRef: RefObject<HTMLDivElement>;
}> = observer(function CarClassChip({ filterViewModel, carClassChipsScrollRef }) {
  const { t } = useTranslation();
  const { carClass } = filterViewModel;
  // const carClassChipsScrollRef = useRef<HTMLDivElement>(null);
  return (
    <div
      ref={carClassChipsScrollRef}
      className={cx(
        css`
          display: flex;
          flex-direction: row;
          gap: ${SPACING.spacing8};

          overflow-x: scroll;
          width: 100%;
          padding-right: ${SPACING.spacing32};
        `,
        hideScrollBar,
      )}
    >
      <CheckboxesWithAllToggle
        possibleValues={INITIAL_VEHCLASS_ZOD._def.values}
        checked={filterViewModel.carClass}
        overrides={{
          AllCheckRenderer: ({ allChecked, setAllChecked }) => {
            return (
              <CarClassChipsItem
                active={allChecked}
                hideActiveCheckbox
                onClick={() => {
                  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                  setAllChecked(!allChecked);
                }}
              >
                전체
              </CarClassChipsItem>
            );
          },
          ItemRenderer: ({ value }) => {
            const active = carClass.value.has(value);
            return (
              <CarClassChipsItem
                active={active}
                onClick={(e) => {
                  if (active) {
                    carClass.value.delete(value);
                    return;
                  }

                  carClass.value.add(value);

                  if (carClassChipsScrollRef.current) {
                    const rootElemParentWidth =
                      carClassChipsScrollRef.current.parentElement?.clientWidth;

                    if (rootElemParentWidth) {
                      carClassChipsScrollRef.current.scrollTo({
                        left: e.currentTarget.offsetLeft - rootElemParentWidth / 2 + 8,
                        behavior: 'smooth',
                      });
                    }
                  }
                }}
              >
                {t(value)}
              </CarClassChipsItem>
            );
          },
        }}
      />
    </div>
  );
});
