/* eslint-disable max-len */
import { css, cx } from '@emotion/css';
import { observer } from 'mobx-react-lite';

import { hideScrollBar } from '^/constants/commonStyles';

import { FooterMobile } from '../Footer/Footer.Mobile';
import { HeaderMobile } from '../Header/Header.Mobile';
import { LayoutProps } from '.';

export const LayoutMobile: React.FC<LayoutProps> = observer(function LayoutMobile({
  children,
  withoutFooter,
  withoutFooterMarginTop,
  withoutOverflowScroll,
  scrollAreaKey,
  withoutNavigationFullRefresh,
  classOverrides,
}) {
  return (
    <div
      id="Layout2_Mobile_Root"
      className={cx(
        css`
          position: relative;
          width: 100%;
          height: 100%;
          overflow: hidden;
          overscroll-behavior: contain;
        `,
      )}
    >
      <HeaderMobile withoutFullRefresh={withoutNavigationFullRefresh} />
      <div
        id="Layout2_Mobile_Content"
        key={scrollAreaKey}
        className={cx(
          css`
            position: absolute;
            top: 60px;
            left: 0;
            right: 0;
            bottom: 0;
            overflow: scroll;
            overscroll-behavior: contain;
            background-color: ${classOverrides?.layout2MobileContentWrapperBg};
          `,
          hideScrollBar,
        )}
      >
        <div
          id="Layout2_Mobile_Content_inner"
          className={css`
            display: flex;
            flex-direction: column;
            justify-content: space-between;
          `}
          style={
            withoutOverflowScroll
              ? {
                  height: '100%',
                }
              : undefined
          }
        >
          {children}
          {!withoutFooter && (
            <div
              className={css`
                margin-top: ${!withoutFooterMarginTop ? '24px' : '0'};
              `}
            >
              <FooterMobile />
            </div>
          )}
        </div>
      </div>
    </div>
  );
});
