/* eslint-disable import/no-named-as-default */
import React, { useEffect, useMemo } from 'react';

import { observer } from 'mobx-react-lite';
import { useMediaQuery } from 'react-responsive';

// import { actions, sendTrackingLog } from '../../api/trackingLog';
import { AppContext } from '../../AppContext/AppContext';
import Layout from '../../components/Layout';
import { AlertPopup } from '../../components/Popup/AlertPopup/AlertPopup';
import { COLORS } from '../../constants/commonStyles';
import { MobxValue } from '../../util/MobxValue';

import { HomeRouteParams } from './viewmodel/Home.RouteParams';
import { HomeViewModel } from './viewmodel/Home.viewmodel';
import { HomeDesktop } from './desktop/Home.desktop';
import { HomeMobile } from './mobile/Home.mobile';

export const HomeWrapper: React.FC<
  {
    appContext: AppContext;
  } & HomeRouteParams
> = observer(function HomePage2Wrapper(props) {
  const { appContext, ...routeParams } = props;

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const viewmodel = useMemo(
    () => new HomeViewModel(appContext, new MobxValue(routeParams), isMobile, 13),
    [isMobile],
  );

  (window as any).viewmodel = viewmodel;

  useEffect(() => {
    // NOTE: 도시 자동 선택
    // NOTE: querystring에 cityCode, cityName이 있을 경우 해당 도시를 자동 선택
    if (sessionStorage.preSelectedCity) {
      const preSelectedCity = JSON.parse(sessionStorage.getItem('preSelectedCity') || '');
      if (preSelectedCity?.cityCode && preSelectedCity?.cityName) {
        viewmodel.carListingSearchBoxInputVM.pickupCitySearch.selectedCityCode.set(
          preSelectedCity.cityCode,
        );
        viewmodel.carListingSearchBoxInputVM.pickupCitySearch.searchInput.set(
          preSelectedCity.cityName,
        );
        if (isMobile) {
          viewmodel.carListingSearchBox.stepManager.pushStep({ type: '2_rental_office' });
        }
      }
    }
  }, []);

  useEffect(() => {
    viewmodel.routeParams.setValue(routeParams);
  }, [routeParams]);

  useEffect(() => {
    viewmodel.carListingSearchBox.stepManager.updateRouteParams(routeParams);
  }, [routeParams, viewmodel.carListingSearchBox.stepManager]);

  const DifferentLocationAlert = () => (
    <AlertPopup
      isMobile
      title="해당 도시는 대여/반납 장소를 다르게 지정할 수 없습니다."
      closeText="닫기"
      visible={viewmodel.carListingSearchBox.showDifferentLocationAlert}
      handleClose={() => viewmodel.carListingSearchBox.showDifferentLocationAlert.set(false)}
    />
  );

  /* 모바일 */
  if (isMobile) {
    return (
      <Layout withoutMaxWidth>
        <DifferentLocationAlert />
        <HomeMobile viewmodel={viewmodel} />
      </Layout>
    );
  }

  /* 데스크탑 */
  return (
    <Layout>
      <div className="w-full flex justify-center mt-[56px]">
        <div className="max-w-[1320px]">
          <DifferentLocationAlert />
          <HomeDesktop viewmodel={viewmodel} />
        </div>
      </div>
    </Layout>
  );
});
