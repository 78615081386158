/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';

import { css } from '@emotion/css';
import { useNavigate } from 'react-router';

import {
  borders,
  MARGINS,
  PADDINGS,
  COLORS2,
  COLORS2_AS_BACKGROUND,
  COLORS2_AS_FOREGROUND,
  font2,
} from '^/constants/commonStyles';
import { VENDOR_LOGOS } from '^/constants/channel/logoImages.constants';
import { isDefined } from '^/types/utils/isDefined';
import { ListingPage2CardViewModel } from '../viewmodel/ListingPage2.card.viewmodel';

export const ListingPage2MobileListGroupBottomSheetBodyArea: React.FC<{
  viewmodel: ListingPage2CardViewModel;
}> = ({ viewmodel }) => {
  const navigate = useNavigate();
  const { lowestDomesticPrice } = viewmodel;

  return (
    <div
      className={[
        PADDINGS.LEFT_AND_RIGHT.spacing24,
        // borders.DEBUG_orangeBorderDashed,
        css`
          margin-top: 0;
          margin-bottom: 0;
          padding-bottom: 50px;
          max-height: calc(100vh - 330px);
          overflow-y: auto;
        `,
      ].join(' ')}
    >
      {viewmodel.groupedByVendors.map(([brandId, carChoices], nth) => {
        const sortedChoices = [carChoices.minPODChoice, carChoices.minPPDChoice]
          .filter(isDefined)
          .sort((a, b) => {
            // order: PPD first
            if (a.payment.type === 'PPD') {
              return -1;
            }
            return 1;
          });
        return (
          <>
            {nth !== 0 ? (
              <div
                className={[
                  css`
                    background-color: #eaeaea;
                    width: 100%;
                    height: 1px;
                  `,
                  MARGINS.TOP_AND_BOTTOM.spacing16,
                  // borders.DEBUG_blueBorderDashed,
                ].join(' ')}
              />
            ) : (
              <div
                style={{
                  height: 3,
                }}
              />
            )}
            <div
              className={[
                // borders.DEBUG_greenBorderDashed,
                MARGINS.TOP.spacing16,
              ].join(' ')}
            >
              <img
                alt="vendor_logo"
                src={VENDOR_LOGOS[brandId]}
                className={[
                  css`
                    height: 18px;
                  `,
                  // borders.DEBUG_blueBorderDashed,
                ].join(' ')}
              />
              <div
                className={[
                  // borders.DEBUG_blueBorderDashed,
                ].join(' ')}
              >
                {sortedChoices.map((choiceItem) => {
                  const isDiscounted = choiceItem.payment.isDiscounted;
                  const foreignDiscountPrice = Math.ceil(
                    Number(choiceItem.payment.foreignCurrency) -
                      Number(choiceItem.payment.discountForeign),
                  );
                  const domesticDiscountPrice =
                    Number(choiceItem.payment.domesticPrice) -
                    Number(choiceItem.payment.discountDomestic);
                  return (
                    <div
                      key={choiceItem.core.id}
                      className={[
                        MARGINS.TOP.spacing12,
                        COLORS2_AS_BACKGROUND.sub.MrtGray3,
                        PADDINGS.TOP_AND_BOTTOM.spacing10,
                        PADDINGS.LEFT_AND_RIGHT.spacing16,
                        // borders.DEBUG_purpleBorderDashed,
                        css`
                          display: flex;
                          justify-content: space-between;
                          align-items: center;
                          border-radius: 10px;
                          height: 58px;
                        `,
                      ].join(' ')}
                      onClick={() => {
                        // sendTrackingLog({
                        //   action: actions.CAR_LIST.CLICK_CAR_RESERVATION,
                        //   data: choiceItem.core.modelName,
                        // });
                        viewmodel.gotoBooking(choiceItem.payment.type, navigate, choiceItem);
                      }}
                      role="menuitem"
                      tabIndex={-1}
                    >
                      <span className={[font2.Caption1_Bold].join(' ')}>
                        {choiceItem.payment.type === 'POD' ? '현장 결제' : '즉시 결제'}
                      </span>
                      <div
                        className={[
                          css`
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                          `,
                          // borders.DEBUG_greenBorderDashed,
                        ].join(' ')}
                      >
                        <div>
                          {choiceItem.payment.domesticPrice === lowestDomesticPrice && (
                            <span
                              className={[
                                COLORS2_AS_BACKGROUND.main.MrtBlue1,
                                COLORS2_AS_FOREGROUND.main.MrtWhite,
                                PADDINGS.TOP_AND_BOTTOM.spacing4,
                                PADDINGS.LEFT_AND_RIGHT.spacing4,
                                css`
                                  height: fit-content;
                                  border-radius: 5px;
                                  font-family: Pretendard;
                                  font-size: 10px;
                                `,
                              ].join(' ')}
                            >
                              최저가
                            </span>
                          )}
                        </div>
                        <div
                          id="priceTags"
                          className={[
                            css`
                              display: flex;
                              flex-direction: column;
                              align-items: end;
                            `,
                            MARGINS.LEFT.spacing8,
                          ].join(' ')}
                        >
                          {isDiscounted && (
                            <h4
                              className={css`
                                text-decoration: line-through;
                                color: #717171;
                                font-weight: 700;
                                line-height: 16px;
                                font-size: 13px;
                              `}
                            >
                              {choiceItem.payment.foreignCurrency.toLocaleString()}{' '}
                              {choiceItem.payment.foreignCurrencyCode}
                            </h4>
                          )}
                          <span
                            className={[
                              font2.Headline1_Bold,
                              MARGINS.LEFT.spacing6,
                              isDiscounted && COLORS2_AS_FOREGROUND.main.GuamBlue1,
                              isDiscounted &&
                                css`
                                  line-height: 18px;
                                `,
                            ].join(' ')}
                          >
                            {isDiscounted
                              ? foreignDiscountPrice.toLocaleString()
                              : choiceItem.payment.foreignCurrency.toLocaleString()}
                            &nbsp;
                            {choiceItem.payment.foreignCurrencyCode}
                          </span>
                          <span
                            className={[
                              font2.Caption2_Light,
                              COLORS2_AS_FOREGROUND.sub.MrtGray1,
                              isDiscounted &&
                                css`
                                  line-height: 12px;
                                `,
                            ].join(' ')}
                          >
                            약{' '}
                            {isDiscounted
                              ? domesticDiscountPrice.toLocaleString()
                              : choiceItem.payment.domesticPrice.toLocaleString()}{' '}
                            원
                          </span>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </>
        );
      })}
    </div>
  );
};
