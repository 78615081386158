import { useRef } from 'react';

import { Tab } from '@headlessui/react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import LoadingWithVendorLogo from '^/components/Loading/LoadingWithVendorLogo';
import { typeRouter } from '^/typeRoute/typeRoutes';

import { classNames } from '../../../util';
import { ReservationDetailsPageViewModel } from '../ReservationDetailsPage.viewmodel';

import { ReservationDetailsBill } from './components/Bill';
import { ReservationDetailsDriverInfo } from './components/DriverInfo';
import { InsuranceInfoModal } from './components/InsuranceInfoModal';
import { ReservationDetailsVehicleInfo } from './components/VehicleInfo';
import { FooterMobile } from '^/components/Footer/Footer.Mobile';

export const ReservationDetailPageMobile: React.FC<{
  viewmodel: ReservationDetailsPageViewModel;
  onClickCancelReservation: () => void;
}> = observer(function ReservationDetailMobile({ viewmodel, onClickCancelReservation }) {
  const { t } = useTranslation();
  const scrollAreaRef = useRef<HTMLElement>();
  if (scrollAreaRef.current === undefined) {
    const scrollArea = document.getElementById('Layout2_Mobile_Content');
    if (scrollArea) {
      scrollAreaRef.current = scrollArea;
    }
  }
  const scrollTop = () => scrollAreaRef.current?.scrollTo({ top: 0, behavior: 'smooth' });

  if (viewmodel.reservationDetail.status === 'loading') {
    return <LoadingWithVendorLogo />;
  }
  if (viewmodel.reservationDetail.status === 'failure') {
    return (
      <div>
        <h1> 에러 발생 </h1>
        <pre>{JSON.stringify(viewmodel.reservationDetail.failure, null, 2)}</pre>
      </div>
    );
  }
  const reservationDetails = viewmodel.reservationDetail.value;
  return (
    <>
      <InsuranceInfoModal
        insuranceServices={reservationDetails.insuranceServices}
        insuranceName={
          reservationDetails.insurance_info_json.code
            ? t(`insurance_name.${reservationDetails.insurance_info_json.code}`)
            : '기본 할인요금'
        }
        isOpen={viewmodel.showInsuranceInfoMobilePopupPage}
        onCloseModal={() => {
          typeRouter.session.back(1);
        }}
      />
      <Tab.Group>
        <div className="fixed top-[60px] w-full bg-white">
          <h2 className="p-[20px] w-full bg-white py-5 px-6 text-semihuge font-bold">예약정보</h2>
          <Tab.List className="flex justify-between px-6">
            <Tab
              key="vehicle"
              className={({ selected }) =>
                classNames(
                  'pb-3 border-b w-[33%]',
                  selected ? 'border-primary-skyblue' : 'border-transparent',
                )
              }
              onClick={() => scrollTop()}
            >
              차량 및 일정
            </Tab>
            <Tab
              key="driver"
              className={({ selected }) =>
                classNames(
                  'pb-3  border-b w-[33%]',
                  selected ? 'border-primary-skyblue' : 'border-transparent',
                )
              }
              onClick={() => scrollTop()}
            >
              운전자 정보
            </Tab>
            <Tab
              key="fee"
              className={({ selected }) =>
                classNames(
                  'pb-3  border-b w-[33%]',
                  selected ? 'border-primary-skyblue' : 'border-transparent',
                )
              }
              onClick={() => scrollTop()}
            >
              요금 내역
            </Tab>
          </Tab.List>
          <hr className="h-px bg-secondary-gray-light" />
        </div>
        <Tab.Panels className="mt-[120px] p-[15px]">
          <Tab.Panel key="vehicle-info">
            <ReservationDetailsVehicleInfo
              data={viewmodel.reservationDetail.value}
              onClickCancelReservation={onClickCancelReservation}
              onClickShowInsuranceInfo={() => {
                viewmodel.setShowInsurancePopup(true);
              }}
            />
          </Tab.Panel>
          <Tab.Panel key="driver-info">
            <ReservationDetailsDriverInfo
              data={viewmodel.reservationDetail.value}
              onClickCancelReservation={onClickCancelReservation}
            />
          </Tab.Panel>
          <Tab.Panel key="fee-info">
            <ReservationDetailsBill
              data={viewmodel.reservationDetail.value}
              onClickCancelReservation={onClickCancelReservation}
            />
          </Tab.Panel>
        </Tab.Panels>
        <FooterMobile />
      </Tab.Group>
    </>
  );
});
