import { useState } from 'react';

import { useQuery } from 'react-query';
import { useMediaQuery } from 'react-responsive';

import { cancelReservationOT, getReservationOT } from '^/api/reservation';
import { ServerErrorView } from '^/commonComponents/ui/ErrorViews/ServerErrorView';
import { Layout } from '^/components/Layout';
import LoadingWithVendorLogo from '^/components/Loading/LoadingWithVendorLogo';
import { AlertPopupDesktop } from '^/components/Popup/AlertPopup/AlertPopup.desktop';
import { AlertPopupMobile } from '^/components/Popup/AlertPopup/AlertPopup.mobile';
import { typeRouter } from '^/typeRoute/typeRoutes';
import { MobxInputValue } from '^/util/MobxInputValue';

import BillCancelButton from './Bill/Bill.Booked.CancelButton';
import ConfirmReservationCancelPopup from '../ConfirmReservationCancelPopup';

import ReservationDetailDesktop from './ReservationDetail.Desktop';
import ReservationDetailMobile from './ReservationDetail.Mobile';

const ReservationDetailOnlineTravel = (props) => {
  const { id, vendorId, lastName } = props;
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const [showCancleConfirmPopup, setShowCancelConfirmPopup] = useState(new MobxInputValue(false));
  const [loading, setLoading] = useState(false);
  const [isAlertPopupShow, setIsAlertPopupShow] = useState(new MobxInputValue(false));
  const [isCancelError, setIsCancelError] = useState(false);

  const onClickCancelReservationConfirm = async (cancel_reason) => {
    setShowCancelConfirmPopup(new MobxInputValue(false));
    setLoading(true);
    try {
      const response = await cancelReservationOT(id, cancel_reason);
      if (response.data.success) {
        setLoading(false);
        setIsAlertPopupShow(new MobxInputValue(true));
      }
    } catch (e) {
      console.error(e);
      setLoading(false);
      setIsCancelError(true);
    }
  };

  const {
    data: resData,
    isLoading,
    isError,
    error,
  } = useQuery(
    ['reservationDetailOT', id],
    async () => {
      const response = await getReservationOT(id);
      return response.data;
    },
    {
      retry: 1,
      refetchOnMount: 'always',
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
      onError: (err) => console.log(err),
    },
  );

  if (isLoading) {
    return <LoadingWithVendorLogo />;
  }
  if (isError) {
    return (
      <ServerErrorView
        onClickBack={() => typeRouter.session.back()}
        onClickRetry={() => window.location.reload()}
      />
    );
  }
  if (isCancelError) {
    return (
      <ServerErrorView
        title="예약 취소 중 에러가 발생하였습니다."
        onClickBack={() => {
          typeRouter.session.back();
        }}
        onClickRetry={() => {
          window.location.reload();
        }}
      />
    );
  }
  if (isMobile) {
    return (
      <Layout>
        {loading && <LoadingWithVendorLogo />}
        <ConfirmReservationCancelPopup
          visible={showCancleConfirmPopup}
          handleCancel={() => setShowCancelConfirmPopup(new MobxInputValue(false))}
          handleConfirm={(cancel_reason) => onClickCancelReservationConfirm(cancel_reason)}
          isMobile={isMobile}
        />
        <AlertPopupMobile
          title="예약 취소가 완료되었습니다."
          closeText="닫기"
          visible={isAlertPopupShow}
          handleClose={() => typeRouter.session.back()}
        />
        <ReservationDetailMobile resData={resData} />
        <div className="m-[15px]">
          {resData.booking_info.status === 'booked' && (
            <BillCancelButton
              data={resData}
              setShowCancelConfirmPopup={setShowCancelConfirmPopup}
              isCancelDisabled={false}
            />
          )}
        </div>
      </Layout>
    );
  }
  return (
    <Layout withoutMaxWidth>
      {loading && <LoadingWithVendorLogo />}
      <ReservationDetailDesktop
        resData={resData}
        setShowCancelConfirmPopup={setShowCancelConfirmPopup}
      />
      <ConfirmReservationCancelPopup
        visible={showCancleConfirmPopup}
        handleCancel={() => setShowCancelConfirmPopup(new MobxInputValue(false))}
        handleConfirm={(cancel_reason) => onClickCancelReservationConfirm(cancel_reason)}
        isMobile={isMobile}
      />
      <AlertPopupDesktop
        title="예약 취소가 완료되었습니다."
        closeText="닫기"
        visible={isAlertPopupShow}
        handleClose={() => typeRouter.session.back()}
      />
    </Layout>
  );
};

export default ReservationDetailOnlineTravel;
