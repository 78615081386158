/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';

import { css } from '@emotion/css';
import { format } from 'date-fns';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { ReactComponent as InfoIcon } from '^/assets/icons/ic_info_black.svg';
import {
  borders,
  COLORS,
  SPACING,
  MARGINS,
  PADDINGS,
  COLORS2,
  COLORS2_AS_BACKGROUND,
  COLORS2_AS_FOREGROUND,
  font2,
} from '^/constants/commonStyles';
import { ReservationDetailed } from '^/types/ReservationDetailed/RawReservationDetailed';
import { formatKRWWonAmount } from '^/util/formatKRWWonAmount';
import { parseFloatNumberOrNumberString } from '^/util/zod/numberFromString';

import { HoverToReveal } from '../../../../commonComponents/ui/HoverToReveal';
import { Horizon } from '../../../ReservationList/components/common/Horizon';
import { AdditionalFees2 } from '../../AdditionalFees2';
import { PODOnsitePaymentHoverMessage } from '../../onsiteInfoMessages';

import {
  flexRightmostItemRow,
  flexSpacedRowWithVerticalCenter,
} from './ReservationDetailDesktop.commonStuff';
import { formatDateString } from '^/util/dateFormat';

const wonCurrency = 'KRW';

function isCancelledAndPODReservation(data: ReservationDetailed) {
  if (data.res_status !== 'canceled' || data.pay_type !== 'POD') {
    throw new Error('DummyErrorForTypeFiltering');
  }
  return data;
}

export const ReservationDetailsBillPODCancelled: React.FC<{
  data: ReturnType<typeof isCancelledAndPODReservation>;
}> = observer(function ReservationDetailsBillPODCancelled({ data }) {
  const { t } = useTranslation();
  // const tax = data.tax && JSON.parse(data.tax);
  const currency = data.pay_type === 'POD' ? data.currency : data.extra_equipments_json.currency;
  const rate_foreigner_amount = parseFloatNumberOrNumberString(data.rate_foreigner_amount);

  const cancelTime = formatDateString(data.date_cancel, 'YYYY.MM.DD HH:mm (z)', 'Asia/Seoul');

  return (
    <div
      className={[
        // borders.DEBUG_orangeBorderDashed,
      ].join(' ')}
    >
      <h3 className={[font2.Title3].join(' ')}>취소 내역</h3>

      <div
        id="separator1"
        className={[
          css`
            margin-top: ${SPACING.spacing16};
            margin-bottom: ${SPACING.spacing16};
            background-color: ${COLORS.LIGHT_GREY};
            height: 2px;
          `,
        ].join(' ')}
      />
      <div className="flex flex-col">
        <div className={['mt-4', flexSpacedRowWithVerticalCenter].join(' ')}>
          <p className={[font2.Headline1_Regular].join(' ')}>{t('fee')}</p>
          <p className={[font2.Headline1_Bold].join(' ')}>
            {formatKRWWonAmount(rate_foreigner_amount)}
            &nbsp;
            {data.paymentDetails.onSiteCurrency}
          </p>
        </div>
        {data.extra_charge && (data.extra_charge.additional_amount || 0) > 0 && (
          <div
            className={[
              // 'flex flex-col gap-1.5',
              // borders.DEBUG_greenBorderDashed,
              MARGINS.TOP.spacing12,
            ].join(' ')}
          >
            <AdditionalFees2
              extra_charge={data.extra_charge}
              onSiteCurrency={data.extra_equipments_json.currency}
            />
          </div>
        )}

        <div
          id="separator2"
          className={[
            css`
              margin-top: ${SPACING.spacing16};
              margin-bottom: ${SPACING.spacing16};
              background-color: ${COLORS.LIGHT_GREY};
              height: 2px;
            `,
          ].join(' ')}
        />
        <div
          className={[
            flexSpacedRowWithVerticalCenter,
            // borders.DEBUG_goldDashed,
            css`
              position: relative;
            `,
          ].join(' ')}
        >
          <div
            className={[
              font2.Headline1_Bold,
              // borders.DEBUG_goldDashed,
              css`
                display: flex;
                align-items: center;
                justify-items: center;
                height: fit-content;
                gap: 6px;
              `,
            ].join(' ')}
          >
            <span>현장 결제 예상금액</span>
            <HoverToReveal
              toReveal={
                <div
                  className={[
                    font2.Caption1_Light,
                    PADDINGS.TOP_AND_BOTTOM.spacing16,
                    COLORS2_AS_BACKGROUND.sub.MrtGray3,
                    css`
                      position: absolute;
                      left: 0;
                      right: 0;
                      top: 70%;
                      padding-left: 10px;
                      padding-right: 10px;
                      border-radius: 10px;
                    `,
                  ].join(' ')}
                >
                  {PODOnsitePaymentHoverMessage}
                </div>
              }
            />
          </div>
          <div>
            <p className={[font2.Title2, COLORS2_AS_FOREGROUND.main.MrtBlack].join(' ')}>
              {formatKRWWonAmount(data?.total_foreigner_amount)}
              &nbsp;
              {currency === wonCurrency ? t('won') : currency}
            </p>
          </div>
        </div>
        <p
          className={[
            font2.Caption1_Light,
            COLORS2_AS_FOREGROUND.sub.MrtGray1,
            flexRightmostItemRow,
          ].join(' ')}
        >
          약 {formatKRWWonAmount(data?.total_amount)} {t('won')}
        </p>
      </div>
      <div
        id="separator3"
        className={[
          MARGINS.TOP.spacing16,
          css`
            background-color: ${COLORS.LIGHT_GREY};
            height: 2px;
          `,
        ].join(' ')}
      />
      <div className={['mt-4', flexSpacedRowWithVerticalCenter].join(' ')}>
        <p className={[font2.Headline1_Regular, COLORS2_AS_FOREGROUND.main.MrtBlack].join(' ')}>
          취소 일시
        </p>
        <p className={[font2.Headline1_Bold, COLORS2_AS_FOREGROUND.main.MrtBlack].join(' ')}>
          {cancelTime || '-'}
        </p>
      </div>
    </div>
  );
});
