/* eslint-disable no-nested-ternary */
import React from 'react';

import { css, cx } from '@emotion/css';
import { SPACING, font } from '^/constants/commonStyles';

import { TimeSelectMinuteNullable } from './TimeSelect.constants';
import { classNames } from './TimeSelect.styles';

export const MinuteRenderer: React.FC<{
  value: TimeSelectMinuteNullable;
  isOpen?: boolean;
  isListItem?: boolean;
  disabled?: boolean;
  isNotSelected?: boolean;
}> = ({ value, isOpen, isListItem, disabled, isNotSelected }) => {
  const isEmpty = value === null;

  return (
    <div
      className={cx(
        isListItem
          ? [classNames.btnRendererBasic, font.Body1_Regular]
          : classNames.btnRendererBasic,
        disabled
          ? css`
              color: #cecece !important;
            `
          : '',
        css`
          height: 40px;
          padding: 0 3px 0 8px !important;
        `,
        isListItem &&
          css`
            height: 30px;
          `,
      )}
    >
      <div>
        {isEmpty && <span className={cx(classNames.timeNotSelected)}>00</span>}
        <span className={cx(classNames.timeSelected, isNotSelected && classNames.timeNotSelected)}>
          {value}분
        </span>
      </div>
      {!isListItem && (
        <img
          alt="minute-list-toggle"
          src={isOpen ? './arrow_drop_up_20.svg' : './arrow_drop_down_20.svg'}
          className={css`
            @media (max-width: 768px) {
              width: ${SPACING.spacing16};
              height: ${SPACING.spacing16};
            }
          `}
        />
      )}
    </div>
  );
};
