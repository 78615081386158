import { css, cx } from '@emotion/css';
import { useRecoilValue } from 'recoil';

import { SPACING, COLORS2, font } from '^/constants/commonStyles';
import { LOGO_BY_CHANNEL } from '^/constants/channel/logoImages.constants';
import { NAVIGATION_MENU } from '^/constants/NavigationMenu';
import { subdomainState } from '^/recoil/atoms';
import { typeRouter } from '^/typeRoute/typeRoutes';
import { getItemByChannel } from '^/util/byChannel';

const classNames = {
  headerWrapper_MRT: css`
    max-width: 1060px;
    height: 72px;

    margin: 0 auto;

    display: flex;
    justify-content: space-between;
    align-items: center;
  `,
  navButtonWrapper: cx(
    css`
      display: flex;
      gap: ${SPACING.spacing16};
    `,
    font.Body1_Bold,
  ),
  navButton_MRT: css`
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 8px 20px;
    height: 36px;

    &:hover {
      background-color: ${COLORS2.sub.MrtGray3};
      border-radius: 18px;
    }
  `,
};

export const HeaderDesktop = (props: { withoutFullRefresh?: boolean }) => {
  const subdomain = useRecoilValue(subdomainState);
  return (
    <header className={classNames.headerWrapper_MRT}>
      <button
        type="button"
        onClick={() => {
          if (props.withoutFullRefresh) {
            typeRouter.routes.home2().push();
          } else {
            window.location.href = window.location.origin;
            window.scrollTo(0, 0);
          }
        }}
      >
        <img
          src={getItemByChannel(subdomain, LOGO_BY_CHANNEL)}
          alt="trimo-logo"
          className={css`
            max-width: 225px;
            max-height: 28px;
          `}
        />
      </button>
      <div className={classNames.navButtonWrapper}>
        {NAVIGATION_MENU.map((nav) => (
          <button
            key={nav.name}
            type="button"
            className={classNames.navButton_MRT}
            onClick={() => {
              if (nav.name === '예약 조회하기') {
                window.location.href = typeRouter.routes.reservationCheck().href;
              }
              window.scrollTo(0, 0);
            }}
          >
            {nav.name}
          </button>
        ))}
      </div>
    </header>
  );
};
